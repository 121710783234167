<template>
  <div style="position: fixed;opacity: 0;visibility: hidden;">ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789,.-+*/=°</div>
  <div class="container" :class="'-'+currentStatus">
    <div class="main">
      <div
        class="content"
      >
        <div class="content__header" v-if="setting.base64Url">
          <div class="anglerange">
            <div class="anglerange-content">
              <div
                class="anglerange-content-dummy"
                :style="{
                  'background-position': 300 / 360 * lastAngle + 'px 50%'
                }"
              ><span>{{ lastAngle }}°</span></div>
              <input type="range" max="180" min="-180" v-model="lastAngle">
            </div>
            <div class="header-contoller pc:hidden">
              <button class="icon" :class="{'-disabled': historyIndex <= 0}" @click="undo"><i class="-undo"></i></button>
              <button class="icon" :class="{'-disabled': historyIndex === history.length - 1}" @click="redo"><i class="-redo"></i></button>
            </div>
          </div>
        </div>
        <!-- :style="setCanvasStyle" -->
        <canvas
          id="canvas"
          style="width: 100%; height: 100%;"
        />
            <!-- :style="setCanvasStyle" -->
      </div>
      <div class="sidemenu">
        <div class="icons sp:hidden">
          <button class="icon" :class="{'-disabled': historyIndex <= 0}" @click="undo"><i class="-undo"></i></button>
          <button class="icon" :class="{'-disabled': historyIndex === history.length - 1}" @click="redo"><i class="-redo"></i></button>
          <button class="icon"
            :class="{
              '-disabled': !setting.base64Url
            }"
            @click="handleClickSetActiveObjectZindex('front')"
          >
            <i class="fa-solid fa-arrow-up-wide-short"></i>
            最前面
          </button>
          <button class="icon"
            :class="{
              '-disabled': !setting.base64Url
            }"
            @click="handleClickSetActiveObjectZindex()"
          >
            <i class="fa-solid fa-arrow-down-wide-short"></i>
            最背面
          </button>
          <button class="icon"
            :class="{
              '-disabled': !setting.base64Url
            }"
            @click="handleClickZoom(true)"
          >
            <i class="fa-solid fa-magnifying-glass-plus"></i>
            ズームイン
          </button>
          <button class="icon"
            :class="{
              '-disabled': !setting.base64Url
            }"
            @click="handleClickZoom()"
          >
            <i class="fa-solid fa-magnifying-glass-minus"></i>
            ズームアウト
          </button>
          <button
            class="icon sidemenu-button_ button_"
            :class="{
              '-active': currentStatus == 'pan',
              '-disabled': !setting.base64Url
            }"
            @click="togglePanMode"
          >
            <i class="fa-regular fa-hand"></i>
            移動モード
          </button>
          <button
            class="icon sidemenu-button_ button_"
            :class="{
              '-active': currentStatus == 'select',
              '-disabled': !setting.base64Url
            }"
            @click="select"
          >
            <i class="fa-solid fa-arrow-pointer"></i>
            選択モード
          </button>
          <button
            class="icon sp:hidden sidemenu-button_ button_"
            :class="{
              '-disabled': !setting.base64Url || currentStatus != 'select'
            }"
            @click="copyObject"
          >
            <i class="fa-solid fa-copy"></i>
            選択オブジェクト複製
          </button>
          <button
            class="icon sp:hidden sidemenu-button_ button_"
            :class="{
              '-disabled': !setting.base64Url || currentStatus != 'select'
            }"
            @click="deleteObject"
          >
            <i class="fa-solid fa-trash"></i>
            選択オブジェクト削除
          </button>
          <button
            class="icon sidemenu-button_ button_"
            :class="{
              '-active': currentStatus == 'text',
              '-disabled': !setting.base64Url
            }"
            @click="isAddStringFlag = true;currentStatus = 'text';"
          >
            <i class="fa-solid fa-font"></i>
            文字追加
          </button>
          <button
            class="icon sidemenu-button_ button_"
            :class="{
              '-active': currentStatus == 'templateString',
              '-disabled': !setting.base64Url
            }"
            @click="isAddTemplateStringOpen = true"
          >
            <i class="fa-solid fa-rectangle-ad"></i>
            テンプレート<br>文字列追加
          </button>
          <button
            class="icon sidemenu-button_ button_"
            :class="{
              '-active': currentStatus == 'draw',
              '-disabled': !setting.base64Url
            }"
            @click="draw"
          >
            <i class="fa-solid fa-pen"></i>
            ペン
          </button>
          <button
            class="icon sidemenu-button_ button_"
            :class="{
              '-active': currentStatus == 'rectangle' || currentStatus == 'arrow',
              '-disabled': !setting.base64Url
            }"
            @click="isAddRectangleOpen = true"
          >
            <i class="-shapes-light"></i>
            矩形
            <!-- <div class="color-sample" :class="{'-show': isColorShowFlag == true}">
              <i class="fa-solid fa-circle"></i>
              <i class="fa-regular fa-circle"></i>
              <i class="fa-solid fa-square"></i>
              <i class="fa-regular fa-square"></i>
            </div> -->
          </button>
          <!-- <button
            class="icon sidemenu-button_ button_"
            :class="{
              '-active': currentStatus == 'arrow',
              '-disabled': !setting.base64Url
            }"
            @click="arrow"
          >
            <i class="fa-solid fa-right-long" style="transform: rotate(45deg);"></i>
            矢印
          </button> -->
          <div class="icon -svg"
            :class="{
              '-active': currentStatus == 'svg',
              '-disabled': !setting.base64Url
            }"
          >
            <button class="svglist" @click="isSVGShowFlag = !isSVGShowFlag; isColorShowFlag = false;">
              <!-- <i class="fa-solid fa-face-smile"></i> -->
              <i class="fa-solid fa-icons"></i>
            </button>
            <div class="svglist-content" :class="{'-show': isSVGShowFlag == true}">
              <div class="svglist__group">
                <div class="svglist__item" @click="handleClickAddSVG('/assets/icons/circle-0.svg')"><img src="/assets/icons/circle-0.svg" alt=""></div>
                <div class="svglist__item" @click="handleClickAddSVG('/assets/icons/circle-1.svg')"><img src="/assets/icons/circle-1.svg" alt=""></div>
                <div class="svglist__item" @click="handleClickAddSVG('/assets/icons/circle-2.svg')"><img src="/assets/icons/circle-2.svg" alt=""></div>
                <div class="svglist__item" @click="handleClickAddSVG('/assets/icons/circle-3.svg')"><img src="/assets/icons/circle-3.svg" alt=""></div>
                <div class="svglist__item" @click="handleClickAddSVG('/assets/icons/circle-4.svg')"><img src="/assets/icons/circle-4.svg" alt=""></div>
                <div class="svglist__item" @click="handleClickAddSVG('/assets/icons/circle-5.svg')"><img src="/assets/icons/circle-5.svg" alt=""></div>
                <div class="svglist__item" @click="handleClickAddSVG('/assets/icons/circle-6.svg')"><img src="/assets/icons/circle-6.svg" alt=""></div>
                <div class="svglist__item" @click="handleClickAddSVG('/assets/icons/circle-7.svg')"><img src="/assets/icons/circle-7.svg" alt=""></div>
                <div class="svglist__item" @click="handleClickAddSVG('/assets/icons/circle-8.svg')"><img src="/assets/icons/circle-8.svg" alt=""></div>
                <div class="svglist__item" @click="handleClickAddSVG('/assets/icons/circle-9.svg')"><img src="/assets/icons/circle-9.svg" alt=""></div>
                <div class="svglist__item" @click="handleClickAddSVG('/assets/icons/circle-a.svg')"><img src="/assets/icons/circle-a.svg" alt=""></div>
                <div class="svglist__item" @click="handleClickAddSVG('/assets/icons/circle-b.svg')"><img src="/assets/icons/circle-b.svg" alt=""></div>
                <div class="svglist__item" @click="handleClickAddSVG('/assets/icons/circle-c.svg')"><img src="/assets/icons/circle-c.svg" alt=""></div>
                <div class="svglist__item" @click="handleClickAddSVG('/assets/icons/circle-d.svg')"><img src="/assets/icons/circle-d.svg" alt=""></div>
                <div class="svglist__item" @click="handleClickAddSVG('/assets/icons/circle-e.svg')"><img src="/assets/icons/circle-e.svg" alt=""></div>
                <div class="svglist__item" @click="handleClickAddSVG('/assets/icons/circle-f.svg')"><img src="/assets/icons/circle-f.svg" alt=""></div>
                <div class="svglist__item" @click="handleClickAddSVG('/assets/icons/circle-g.svg')"><img src="/assets/icons/circle-g.svg" alt=""></div>
                <div class="svglist__item" @click="handleClickAddSVG('/assets/icons/circle-h.svg')"><img src="/assets/icons/circle-h.svg" alt=""></div>
                <div class="svglist__item" @click="handleClickAddSVG('/assets/icons/circle-i.svg')"><img src="/assets/icons/circle-i.svg" alt=""></div>
                <div class="svglist__item" @click="handleClickAddSVG('/assets/icons/circle-j.svg')"><img src="/assets/icons/circle-j.svg" alt=""></div>
                <div class="svglist__item" @click="handleClickAddSVG('/assets/icons/circle-k.svg')"><img src="/assets/icons/circle-k.svg" alt=""></div>
                <div class="svglist__item" @click="handleClickAddSVG('/assets/icons/circle-l.svg')"><img src="/assets/icons/circle-l.svg" alt=""></div>
                <div class="svglist__item" @click="handleClickAddSVG('/assets/icons/circle-m.svg')"><img src="/assets/icons/circle-m.svg" alt=""></div>
                <div class="svglist__item" @click="handleClickAddSVG('/assets/icons/circle-n.svg')"><img src="/assets/icons/circle-n.svg" alt=""></div>
                <div class="svglist__item" @click="handleClickAddSVG('/assets/icons/circle-o.svg')"><img src="/assets/icons/circle-o.svg" alt=""></div>
                <div class="svglist__item" @click="handleClickAddSVG('/assets/icons/circle-p.svg')"><img src="/assets/icons/circle-p.svg" alt=""></div>
                <div class="svglist__item" @click="handleClickAddSVG('/assets/icons/circle-q.svg')"><img src="/assets/icons/circle-q.svg" alt=""></div>
                <div class="svglist__item" @click="handleClickAddSVG('/assets/icons/circle-r.svg')"><img src="/assets/icons/circle-r.svg" alt=""></div>
                <div class="svglist__item" @click="handleClickAddSVG('/assets/icons/circle-s.svg')"><img src="/assets/icons/circle-s.svg" alt=""></div>
                <div class="svglist__item" @click="handleClickAddSVG('/assets/icons/circle-t.svg')"><img src="/assets/icons/circle-t.svg" alt=""></div>
                <div class="svglist__item" @click="handleClickAddSVG('/assets/icons/circle-u.svg')"><img src="/assets/icons/circle-u.svg" alt=""></div>
                <div class="svglist__item" @click="handleClickAddSVG('/assets/icons/circle-v.svg')"><img src="/assets/icons/circle-v.svg" alt=""></div>
                <div class="svglist__item" @click="handleClickAddSVG('/assets/icons/circle-w.svg')"><img src="/assets/icons/circle-w.svg" alt=""></div>
                <div class="svglist__item" @click="handleClickAddSVG('/assets/icons/circle-x.svg')"><img src="/assets/icons/circle-x.svg" alt=""></div>
                <div class="svglist__item" @click="handleClickAddSVG('/assets/icons/circle-y.svg')"><img src="/assets/icons/circle-y.svg" alt=""></div>
                <div class="svglist__item" @click="handleClickAddSVG('/assets/icons/circle-z.svg')"><img src="/assets/icons/circle-z.svg" alt=""></div>
              </div>
              <div class="svglist__group">
                <div class="svglist__item" @click="handleClickAddSVG('/assets/icons/square-0.svg')"><img src="/assets/icons/square-0.svg" alt=""></div>
                <div class="svglist__item" @click="handleClickAddSVG('/assets/icons/square-1.svg')"><img src="/assets/icons/square-1.svg" alt=""></div>
                <div class="svglist__item" @click="handleClickAddSVG('/assets/icons/square-2.svg')"><img src="/assets/icons/square-2.svg" alt=""></div>
                <div class="svglist__item" @click="handleClickAddSVG('/assets/icons/square-3.svg')"><img src="/assets/icons/square-3.svg" alt=""></div>
                <div class="svglist__item" @click="handleClickAddSVG('/assets/icons/square-4.svg')"><img src="/assets/icons/square-4.svg" alt=""></div>
                <div class="svglist__item" @click="handleClickAddSVG('/assets/icons/square-5.svg')"><img src="/assets/icons/square-5.svg" alt=""></div>
                <div class="svglist__item" @click="handleClickAddSVG('/assets/icons/square-6.svg')"><img src="/assets/icons/square-6.svg" alt=""></div>
                <div class="svglist__item" @click="handleClickAddSVG('/assets/icons/square-7.svg')"><img src="/assets/icons/square-7.svg" alt=""></div>
                <div class="svglist__item" @click="handleClickAddSVG('/assets/icons/square-8.svg')"><img src="/assets/icons/square-8.svg" alt=""></div>
                <div class="svglist__item" @click="handleClickAddSVG('/assets/icons/square-9.svg')"><img src="/assets/icons/square-9.svg" alt=""></div>
                <div class="svglist__item" @click="handleClickAddSVG('/assets/icons/square-a.svg')"><img src="/assets/icons/square-a.svg" alt=""></div>
                <div class="svglist__item" @click="handleClickAddSVG('/assets/icons/square-b.svg')"><img src="/assets/icons/square-b.svg" alt=""></div>
                <div class="svglist__item" @click="handleClickAddSVG('/assets/icons/square-c.svg')"><img src="/assets/icons/square-c.svg" alt=""></div>
                <div class="svglist__item" @click="handleClickAddSVG('/assets/icons/square-d.svg')"><img src="/assets/icons/square-d.svg" alt=""></div>
                <div class="svglist__item" @click="handleClickAddSVG('/assets/icons/square-e.svg')"><img src="/assets/icons/square-e.svg" alt=""></div>
                <div class="svglist__item" @click="handleClickAddSVG('/assets/icons/square-f.svg')"><img src="/assets/icons/square-f.svg" alt=""></div>
                <div class="svglist__item" @click="handleClickAddSVG('/assets/icons/square-g.svg')"><img src="/assets/icons/square-g.svg" alt=""></div>
                <div class="svglist__item" @click="handleClickAddSVG('/assets/icons/square-h.svg')"><img src="/assets/icons/square-h.svg" alt=""></div>
                <div class="svglist__item" @click="handleClickAddSVG('/assets/icons/square-i.svg')"><img src="/assets/icons/square-i.svg" alt=""></div>
                <div class="svglist__item" @click="handleClickAddSVG('/assets/icons/square-j.svg')"><img src="/assets/icons/square-j.svg" alt=""></div>
                <div class="svglist__item" @click="handleClickAddSVG('/assets/icons/square-k.svg')"><img src="/assets/icons/square-k.svg" alt=""></div>
                <div class="svglist__item" @click="handleClickAddSVG('/assets/icons/square-l.svg')"><img src="/assets/icons/square-l.svg" alt=""></div>
                <div class="svglist__item" @click="handleClickAddSVG('/assets/icons/square-m.svg')"><img src="/assets/icons/square-m.svg" alt=""></div>
                <div class="svglist__item" @click="handleClickAddSVG('/assets/icons/square-n.svg')"><img src="/assets/icons/square-n.svg" alt=""></div>
                <div class="svglist__item" @click="handleClickAddSVG('/assets/icons/square-o.svg')"><img src="/assets/icons/square-o.svg" alt=""></div>
                <div class="svglist__item" @click="handleClickAddSVG('/assets/icons/square-p.svg')"><img src="/assets/icons/square-p.svg" alt=""></div>
                <div class="svglist__item" @click="handleClickAddSVG('/assets/icons/square-q.svg')"><img src="/assets/icons/square-q.svg" alt=""></div>
                <div class="svglist__item" @click="handleClickAddSVG('/assets/icons/square-r.svg')"><img src="/assets/icons/square-r.svg" alt=""></div>
                <div class="svglist__item" @click="handleClickAddSVG('/assets/icons/square-s.svg')"><img src="/assets/icons/square-s.svg" alt=""></div>
                <div class="svglist__item" @click="handleClickAddSVG('/assets/icons/square-t.svg')"><img src="/assets/icons/square-t.svg" alt=""></div>
                <div class="svglist__item" @click="handleClickAddSVG('/assets/icons/square-u.svg')"><img src="/assets/icons/square-u.svg" alt=""></div>
                <div class="svglist__item" @click="handleClickAddSVG('/assets/icons/square-v.svg')"><img src="/assets/icons/square-v.svg" alt=""></div>
                <div class="svglist__item" @click="handleClickAddSVG('/assets/icons/square-w.svg')"><img src="/assets/icons/square-w.svg" alt=""></div>
                <div class="svglist__item" @click="handleClickAddSVG('/assets/icons/square-x.svg')"><img src="/assets/icons/square-x.svg" alt=""></div>
                <div class="svglist__item" @click="handleClickAddSVG('/assets/icons/square-y.svg')"><img src="/assets/icons/square-y.svg" alt=""></div>
                <div class="svglist__item" @click="handleClickAddSVG('/assets/icons/square-z.svg')"><img src="/assets/icons/square-z.svg" alt=""></div>
              </div>
              <div class="svglist__group">
                <div class="svglist__item" @click="handleClickAddSVG('/assets/icons/crow.svg')"><img src="/assets/icons/crow.svg" alt=""></div>
                <div class="svglist__item" @click="handleClickAddSVG('/assets/icons/fish.svg')"><img src="/assets/icons/fish.svg" alt=""></div>
                <div class="svglist__item" @click="handleClickAddSVG('/assets/icons/crab.svg')"><img src="/assets/icons/crab.svg" alt=""></div>
                <div class="svglist__item" @click="handleClickAddSVG('/assets/icons/shrimp.svg')"><img src="/assets/icons/shrimp.svg" alt=""></div>
                <div class="svglist__item" @click="handleClickAddSVG('/assets/icons/squid.svg')"><img src="/assets/icons/squid.svg" alt=""></div>

                <div class="svglist__item" @click="handleClickAddSVG('/assets/icons/car-side.svg')"><img src="/assets/icons/car-side.svg" alt=""></div>
                <div class="svglist__item" @click="handleClickAddSVG('/assets/icons/forklift.svg')"><img src="/assets/icons/forklift.svg" alt=""></div>
                <div class="svglist__item" @click="handleClickAddSVG('/assets/icons/truck-fast.svg')"><img src="/assets/icons/truck-fast.svg" alt=""></div>
                <div class="svglist__item" @click="handleClickAddSVG('/assets/icons/truck-tow.svg')"><img src="/assets/icons/truck-tow.svg" alt=""></div>
                <div class="svglist__item" @click="handleClickAddSVG('/assets/icons/truck.svg')"><img src="/assets/icons/truck.svg" alt=""></div>
                <div class="svglist__item" @click="handleClickAddSVG('/assets/icons/bomb.svg')"><img src="/assets/icons/bomb.svg" alt=""></div>
                <div class="svglist__item" @click="handleClickAddSVG('/assets/icons/skull.svg')"><img src="/assets/icons/skull.svg" alt=""></div>
                <div class="svglist__item" @click="handleClickAddSVG('/assets/icons/skull-crossbones.svg')"><img src="/assets/icons/skull-crossbones.svg" alt=""></div>

                <div class="svglist__item" @click="handleClickAddSVG('/assets/icons/hammer.svg')"><img src="/assets/icons/hammer.svg" alt=""></div>
                <div class="svglist__item" @click="handleClickAddSVG('/assets/icons/shovel.svg')"><img src="/assets/icons/shovel.svg" alt=""></div>
                <div class="svglist__item" @click="handleClickAddSVG('/assets/icons/wrench.svg')"><img src="/assets/icons/wrench.svg" alt=""></div>
                <div class="svglist__item" @click="handleClickAddSVG('/assets/icons/thumbs-down.svg')"><img src="/assets/icons/thumbs-down.svg" alt=""></div>
                <div class="svglist__item" @click="handleClickAddSVG('/assets/icons/thumbs-up.svg')"><img src="/assets/icons/thumbs-up.svg" alt=""></div>

                <div class="svglist__item" @click="handleClickAddSVG('/assets/icons/flag-pennant.svg')"><img src="/assets/icons/flag-pennant.svg" alt=""></div>
                <div class="svglist__item" @click="handleClickAddSVG('/assets/icons/flag-swallowtail.svg')"><img src="/assets/icons/flag-swallowtail.svg" alt=""></div>
                <div class="svglist__item" @click="handleClickAddSVG('/assets/icons/flag.svg')"><img src="/assets/icons/flag.svg" alt=""></div>
                <div class="svglist__item" @click="handleClickAddSVG('/assets/icons/house-chimney.svg')"><img src="/assets/icons/house-chimney.svg" alt=""></div>
                <div class="svglist__item" @click="handleClickAddSVG('/assets/icons/house.svg')"><img src="/assets/icons/house.svg" alt=""></div>
                <div class="svglist__item" @click="handleClickAddSVG('/assets/icons/camera-cctv.svg')"><img src="/assets/icons/camera-cctv.svg" alt=""></div>
                <div class="svglist__item" @click="handleClickAddSVG('/assets/icons/camera.svg')"><img src="/assets/icons/camera.svg" alt=""></div>
                <div class="svglist__item" @click="handleClickAddSVG('/assets/icons/crown.svg')"><img src="/assets/icons/crown.svg" alt=""></div>
                <div class="svglist__item" @click="handleClickAddSVG('/assets/icons/microphone-lines.svg')"><img src="/assets/icons/microphone-lines.svg" alt=""></div>
                <div class="svglist__item" @click="handleClickAddSVG('/assets/icons/phone-volume.svg')"><img src="/assets/icons/phone-volume.svg" alt=""></div>
                <div class="svglist__item" @click="handleClickAddSVG('/assets/icons/plug.svg')"><img src="/assets/icons/plug.svg" alt=""></div>
                <div class="svglist__item" @click="handleClickAddSVG('/assets/icons/key-skeleton.svg')"><img src="/assets/icons/key-skeleton.svg" alt=""></div>
                <div class="svglist__item" @click="handleClickAddSVG('/assets/icons/shield-keyhole.svg')"><img src="/assets/icons/shield-keyhole.svg" alt=""></div>
                <div class="svglist__item" @click="handleClickAddSVG('/assets/icons/siren-on.svg')"><img src="/assets/icons/siren-on.svg" alt=""></div>

                <div class="svglist__item" @click="handleClickAddSVG('/assets/icons/star.svg')"><img src="/assets/icons/star.svg" alt=""></div>
                <div class="svglist__item" @click="handleClickAddSVG('/assets/icons/tag.svg')"><img src="/assets/icons/tag.svg" alt=""></div>
              </div>
              <div class="svglist__group">
                <div class="svglist__item" @click="handleClickAddSVG('/assets/icons/user-helmet-safety.svg')"><img src="/assets/icons/user-helmet-safety.svg" alt=""></div>
                <div class="svglist__item" @click="handleClickAddSVG('/assets/icons/user-large.svg')"><img src="/assets/icons/user-large.svg" alt=""></div>
                <div class="svglist__item" @click="handleClickAddSVG('/assets/icons/user-pilot.svg')"><img src="/assets/icons/user-pilot.svg" alt=""></div>
                <div class="svglist__item" @click="handleClickAddSVG('/assets/icons/user-secret.svg')"><img src="/assets/icons/user-secret.svg" alt=""></div>
                <div class="svglist__item" @click="handleClickAddSVG('/assets/icons/user-tie-hair-long.svg')"><img src="/assets/icons/user-tie-hair-long.svg" alt=""></div>
                <div class="svglist__item" @click="handleClickAddSVG('/assets/icons/user-tie-hair.svg')"><img src="/assets/icons/user-tie-hair.svg" alt=""></div>
                <div class="svglist__item" @click="handleClickAddSVG('/assets/icons/user-tie.svg')"><img src="/assets/icons/user-tie.svg" alt=""></div>
                <div class="svglist__item" @click="handleClickAddSVG('/assets/icons/restroom-simple.svg')"><img src="/assets/icons/restroom-simple.svg" alt=""></div>
              </div>
              <div class="svglist__group">
                <div class="svglist__item" @click="handleClickAddSVG('/assets/icons/triangle-exclamation.svg')"><img src="/assets/icons/triangle-exclamation.svg" alt=""></div>
                <div class="svglist__item" @click="handleClickAddSVG('/assets/icons/circle-question.svg')"><img src="/assets/icons/circle-question.svg" alt=""></div>
                <div class="svglist__item" @click="handleClickAddSVG('/assets/icons/octagon-xmark.svg')"><img src="/assets/icons/octagon-xmark.svg" alt=""></div>
                <div class="svglist__item" @click="handleClickAddSVG('/assets/icons/message-check.svg')"><img src="/assets/icons/message-check.svg" alt=""></div>
                <div class="svglist__item" @click="handleClickAddSVG('/assets/icons/message-dots.svg')"><img src="/assets/icons/message-dots.svg" alt=""></div>
                <div class="svglist__item" @click="handleClickAddSVG('/assets/icons/message-exclamation.svg')"><img src="/assets/icons/message-exclamation.svg" alt=""></div>
                <div class="svglist__item" @click="handleClickAddSVG('/assets/icons/message-question.svg')"><img src="/assets/icons/message-question.svg" alt=""></div>
                <div class="svglist__item" @click="handleClickAddSVG('/assets/icons/message-heart.svg')"><img src="/assets/icons/message-heart.svg" alt=""></div>
                <div class="svglist__item" @click="handleClickAddSVG('/assets/icons/message-image.svg')"><img src="/assets/icons/message-image.svg" alt=""></div>
              </div>
            </div>
          </div>
          <div
            class="icon -clip"
            :class="{
              '-active': isClipShowFlag,
              '-disabled': isClipArea || !setting.base64Url
            }"
          >
            <button class="cliplist" @click="isClipShowFlag = !isClipShowFlag; isSVGShowFlag = false;">
              <i class="fa-solid fa-crop"></i>
            </button>
            <div class="cliplist-content" :class="{'-show': isClipShowFlag == true}">
              <button class="size1:1" @click="clip('1:1');"><img src="/assets/icons/1_1.svg" alt="1:1"></button>
              <button class="size6:4" @click="clip('3:2');"><img src="/assets/icons/3_2.svg" alt="3:2"></button>
              <button class="size4:6" @click="clip('2:3');"><img src="/assets/icons/2_3.svg" alt="2:3"></button>
              <button class="size16:9" @click="clip('16:9');"><img src="/assets/icons/16_9.svg" alt="16:9"></button>
              <button class="size9:16" @click="clip('9:16');"><img src="/assets/icons/9_16.svg" alt="9:16"></button>
            </div>
          </div>
          <button
            class="icon sidemenu-button_ button_"
            :class="{
              '-active': currentStatus == 'mosaic',
              '-disabled': !setting.base64Url
            }"
            @click="currentStatus = 'mosaic'"
          >
            <i class="fa-solid fa-chess-board"></i>
            モザイク処理
          </button>
          <div
            class="icon -color"
            :class="{
              '-disabled': !setting.base64Url
            }"
          >
            <button class="color" @click="isColorShowFlag = !isColorShowFlag; isSVGShowFlag = false;">
              <i class="fa-solid fa-circle" :style="{'color': textColor}"></i>
            </button>
            <div class="color-sample" :class="{'-show': isColorShowFlag == true}">
              <i class="fa-solid fa-circle" @click="handleClickSetActiveObjectColor('#ff0200'); isColorShowFlag = false;" style="color: #ff0200;"></i>
              <i class="fa-solid fa-circle" @click="handleClickSetActiveObjectColor('#ff9902'); isColorShowFlag = false;" style="color: #ff9902;"></i>
              <i class="fa-solid fa-circle" @click="handleClickSetActiveObjectColor('#ffff05'); isColorShowFlag = false;" style="color: #ffff05;"></i>
              <i class="fa-solid fa-circle" @click="handleClickSetActiveObjectColor('#0a00ff'); isColorShowFlag = false;" style="color: #0a00ff;"></i>
              <i class="fa-solid fa-circle" @click="handleClickSetActiveObjectColor('#9a01ff'); isColorShowFlag = false;" style="color: #9a01ff;"></i>
              <i class="fa-solid fa-circle" @click="handleClickSetActiveObjectColor('#000000'); isColorShowFlag = false;" style="color: #000000;"></i>
              <i class="fa-solid fa-circle" @click="handleClickSetActiveObjectColor('#808080'); isColorShowFlag = false;" style="color: #808080;"></i>
              <i class="fa-solid fa-circle" @click="handleClickSetActiveObjectColor('#ffffff'); isColorShowFlag = false;" style="color: #ffffff;"></i>
            </div>
          </div>
          <!-- <hr style="margin-top: 20px; margin-bottom: 20px; border-top: 1px solid #ddd;"> -->
          <button
            class="icon sidemenu-button_ button_"
            :class="{
              '-disabled': !setting.base64Url
            }"
            @click="isBlackbordModalOpen = true"
          >
            <i class="fa-solid fa-chalkboard-user"></i>
            電子黒板レイアウト
          </button>
          <!-- <hr style="margin-top: 20px; margin-bottom: 20px; border-top: 1px solid #ddd;"> -->
          <button
            v-if="!isInitImageSet"
            class="icon sidemenu-button_ button_"
            @click="isImageSetModalOpen = true"
          >
            <i class="fa-solid fa-image"></i>
            背景画像設定
          </button>
          <button class="icon -spacer"></button>
          <button
            v-if="!isInitImageSet"
            class="icon -save sidemenu-button_ button_"
            :class="{
              '-disabled': !setting.base64Url
            }"
            @click="download"
          >
            <i class="fa-solid fa-download"></i>
            <!-- <i class="fa-solid fa-download"></i> -->
            画像保存（元画像 + 編集画像）
          </button>
        </div>
        <div class="icons pc:hidden">
          <div
            class="icon -color"
            :class="{
              '-disabled': !setting.base64Url
            }"
          >
            <button class="color" @click="isColorShowFlag = !isColorShowFlag; isSVGShowFlag = false;">
              <i class="fa-solid fa-circle" :style="{'color': textColor}"></i>
            </button>
            <div class="color-sample" :class="{'-show': isColorShowFlag == true}">
              <i class="fa-solid fa-circle" @click="handleClickSetActiveObjectColor('#ff0200'); isColorShowFlag = false;" style="color: #ff0200;"></i>
              <i class="fa-solid fa-circle" @click="handleClickSetActiveObjectColor('#ff9902'); isColorShowFlag = false;" style="color: #ff9902;"></i>
              <i class="fa-solid fa-circle" @click="handleClickSetActiveObjectColor('#ffff05'); isColorShowFlag = false;" style="color: #ffff05;"></i>
              <i class="fa-solid fa-circle" @click="handleClickSetActiveObjectColor('#0a00ff'); isColorShowFlag = false;" style="color: #0a00ff;"></i>
              <i class="fa-solid fa-circle" @click="handleClickSetActiveObjectColor('#9a01ff'); isColorShowFlag = false;" style="color: #9a01ff;"></i>
              <i class="fa-solid fa-circle" @click="handleClickSetActiveObjectColor('#000000'); isColorShowFlag = false;" style="color: #000000;"></i>
              <i class="fa-solid fa-circle" @click="handleClickSetActiveObjectColor('#808080'); isColorShowFlag = false;" style="color: #808080;"></i>
              <i class="fa-solid fa-circle" @click="handleClickSetActiveObjectColor('#ffffff'); isColorShowFlag = false;" style="color: #ffffff;"></i>
            </div>
          </div>
          <button
            class="icon sidemenu-button_ button_"
            :class="{
              '-active': currentStatus == 'pan',
              '-disabled': !setting.base64Url
            }"
            @click="togglePanMode"
          >
            <i class="fa-regular fa-hand"></i>
            移動モード
          </button>
          <button
            class="icon sidemenu-button_ button_"
            :class="{
              '-active': currentStatus == 'select',
              '-disabled': !setting.base64Url
            }"
            @click="select"
          >
            <i class="fa-solid fa-arrow-pointer"></i>
            選択モード
          </button>
          <button
            class="icon sp:hidden sidemenu-button_ button_"
            :class="{
              '-disabled': !setting.base64Url || currentStatus != 'select'
            }"
            @click="copyObject"
          >
            <i class="fa-solid fa-copy"></i>
            選択オブジェクト複製
          </button>
          <button
            class="icon sp:hidden sidemenu-button_ button_"
            :class="{
              '-disabled': !setting.base64Url || currentStatus != 'select'
            }"
            @click="deleteObject"
          >
            <i class="fa-solid fa-trash"></i>
            選択オブジェクト削除
          </button>
          <button
            class="icon sidemenu-button_ button_"
            :class="{
              '-active': currentStatus == 'text',
              '-disabled': !setting.base64Url
            }"
            @click="isAddStringFlag = true;currentStatus = 'text';"
          >
            <i class="fa-solid fa-font"></i>
            文字追加
          </button>
          <button
            class="icon sidemenu-button_ button_"
            :class="{
              '-active': currentStatus == 'draw',
              '-disabled': !setting.base64Url
            }"
            @click="draw"
          >
            <i class="fa-solid fa-pen"></i>
            ペン
          </button>
          <button
            class="icon sidemenu-button_ button_"
            :class="{
              '-active': currentStatus == 'rectangle' || currentStatus == 'arrow',
              '-disabled': !setting.base64Url
            }"
            @click="isAddRectangleOpen = true"
          >
            <i class="-shapes-light"></i>
            矩形
            <!-- <div class="color-sample" :class="{'-show': isColorShowFlag == true}">
              <i class="fa-solid fa-circle"></i>
              <i class="fa-regular fa-circle"></i>
              <i class="fa-solid fa-square"></i>
              <i class="fa-regular fa-square"></i>
            </div> -->
          </button>
          <!-- <button
            class="icon sidemenu-button_ button_"
            :class="{
              '-active': currentStatus == 'arrow',
              '-disabled': !setting.base64Url
            }"
            @click="arrow"
          >
            <i class="fa-solid fa-right-long" style="transform: rotate(45deg);"></i>
            矢印
          </button> -->
          <div class="icon -svg"
            :class="{
              '-active': currentStatus == 'svg',
              '-disabled': !setting.base64Url
            }"
          >
            <button class="svglist" @click="isSVGShowFlag = !isSVGShowFlag; isColorShowFlag = false;">
              <!-- <i class="fa-solid fa-face-smile"></i> -->
              <i class="fa-solid fa-icons"></i>
            </button>
            <div class="svglist-content" :class="{'-show': isSVGShowFlag == true}">
              <div class="svglist__group">
                <div class="svglist__item" @click="handleClickAddSVG('/assets/icons/circle-0.svg')"><img src="/assets/icons/circle-0.svg" alt=""></div>
                <div class="svglist__item" @click="handleClickAddSVG('/assets/icons/circle-1.svg')"><img src="/assets/icons/circle-1.svg" alt=""></div>
                <div class="svglist__item" @click="handleClickAddSVG('/assets/icons/circle-2.svg')"><img src="/assets/icons/circle-2.svg" alt=""></div>
                <div class="svglist__item" @click="handleClickAddSVG('/assets/icons/circle-3.svg')"><img src="/assets/icons/circle-3.svg" alt=""></div>
                <div class="svglist__item" @click="handleClickAddSVG('/assets/icons/circle-4.svg')"><img src="/assets/icons/circle-4.svg" alt=""></div>
                <div class="svglist__item" @click="handleClickAddSVG('/assets/icons/circle-5.svg')"><img src="/assets/icons/circle-5.svg" alt=""></div>
                <div class="svglist__item" @click="handleClickAddSVG('/assets/icons/circle-6.svg')"><img src="/assets/icons/circle-6.svg" alt=""></div>
                <div class="svglist__item" @click="handleClickAddSVG('/assets/icons/circle-7.svg')"><img src="/assets/icons/circle-7.svg" alt=""></div>
                <div class="svglist__item" @click="handleClickAddSVG('/assets/icons/circle-8.svg')"><img src="/assets/icons/circle-8.svg" alt=""></div>
                <div class="svglist__item" @click="handleClickAddSVG('/assets/icons/circle-9.svg')"><img src="/assets/icons/circle-9.svg" alt=""></div>
                <div class="svglist__item" @click="handleClickAddSVG('/assets/icons/circle-a.svg')"><img src="/assets/icons/circle-a.svg" alt=""></div>
                <div class="svglist__item" @click="handleClickAddSVG('/assets/icons/circle-b.svg')"><img src="/assets/icons/circle-b.svg" alt=""></div>
                <div class="svglist__item" @click="handleClickAddSVG('/assets/icons/circle-c.svg')"><img src="/assets/icons/circle-c.svg" alt=""></div>
                <div class="svglist__item" @click="handleClickAddSVG('/assets/icons/circle-d.svg')"><img src="/assets/icons/circle-d.svg" alt=""></div>
                <div class="svglist__item" @click="handleClickAddSVG('/assets/icons/circle-e.svg')"><img src="/assets/icons/circle-e.svg" alt=""></div>
                <div class="svglist__item" @click="handleClickAddSVG('/assets/icons/circle-f.svg')"><img src="/assets/icons/circle-f.svg" alt=""></div>
                <div class="svglist__item" @click="handleClickAddSVG('/assets/icons/circle-g.svg')"><img src="/assets/icons/circle-g.svg" alt=""></div>
                <div class="svglist__item" @click="handleClickAddSVG('/assets/icons/circle-h.svg')"><img src="/assets/icons/circle-h.svg" alt=""></div>
                <div class="svglist__item" @click="handleClickAddSVG('/assets/icons/circle-i.svg')"><img src="/assets/icons/circle-i.svg" alt=""></div>
                <div class="svglist__item" @click="handleClickAddSVG('/assets/icons/circle-j.svg')"><img src="/assets/icons/circle-j.svg" alt=""></div>
                <div class="svglist__item" @click="handleClickAddSVG('/assets/icons/circle-k.svg')"><img src="/assets/icons/circle-k.svg" alt=""></div>
                <div class="svglist__item" @click="handleClickAddSVG('/assets/icons/circle-l.svg')"><img src="/assets/icons/circle-l.svg" alt=""></div>
                <div class="svglist__item" @click="handleClickAddSVG('/assets/icons/circle-m.svg')"><img src="/assets/icons/circle-m.svg" alt=""></div>
                <div class="svglist__item" @click="handleClickAddSVG('/assets/icons/circle-n.svg')"><img src="/assets/icons/circle-n.svg" alt=""></div>
                <div class="svglist__item" @click="handleClickAddSVG('/assets/icons/circle-o.svg')"><img src="/assets/icons/circle-o.svg" alt=""></div>
                <div class="svglist__item" @click="handleClickAddSVG('/assets/icons/circle-p.svg')"><img src="/assets/icons/circle-p.svg" alt=""></div>
                <div class="svglist__item" @click="handleClickAddSVG('/assets/icons/circle-q.svg')"><img src="/assets/icons/circle-q.svg" alt=""></div>
                <div class="svglist__item" @click="handleClickAddSVG('/assets/icons/circle-r.svg')"><img src="/assets/icons/circle-r.svg" alt=""></div>
                <div class="svglist__item" @click="handleClickAddSVG('/assets/icons/circle-s.svg')"><img src="/assets/icons/circle-s.svg" alt=""></div>
                <div class="svglist__item" @click="handleClickAddSVG('/assets/icons/circle-t.svg')"><img src="/assets/icons/circle-t.svg" alt=""></div>
                <div class="svglist__item" @click="handleClickAddSVG('/assets/icons/circle-u.svg')"><img src="/assets/icons/circle-u.svg" alt=""></div>
                <div class="svglist__item" @click="handleClickAddSVG('/assets/icons/circle-v.svg')"><img src="/assets/icons/circle-v.svg" alt=""></div>
                <div class="svglist__item" @click="handleClickAddSVG('/assets/icons/circle-w.svg')"><img src="/assets/icons/circle-w.svg" alt=""></div>
                <div class="svglist__item" @click="handleClickAddSVG('/assets/icons/circle-x.svg')"><img src="/assets/icons/circle-x.svg" alt=""></div>
                <div class="svglist__item" @click="handleClickAddSVG('/assets/icons/circle-y.svg')"><img src="/assets/icons/circle-y.svg" alt=""></div>
                <div class="svglist__item" @click="handleClickAddSVG('/assets/icons/circle-z.svg')"><img src="/assets/icons/circle-z.svg" alt=""></div>
              </div>
              <div class="svglist__group">
                <div class="svglist__item" @click="handleClickAddSVG('/assets/icons/square-0.svg')"><img src="/assets/icons/square-0.svg" alt=""></div>
                <div class="svglist__item" @click="handleClickAddSVG('/assets/icons/square-1.svg')"><img src="/assets/icons/square-1.svg" alt=""></div>
                <div class="svglist__item" @click="handleClickAddSVG('/assets/icons/square-2.svg')"><img src="/assets/icons/square-2.svg" alt=""></div>
                <div class="svglist__item" @click="handleClickAddSVG('/assets/icons/square-3.svg')"><img src="/assets/icons/square-3.svg" alt=""></div>
                <div class="svglist__item" @click="handleClickAddSVG('/assets/icons/square-4.svg')"><img src="/assets/icons/square-4.svg" alt=""></div>
                <div class="svglist__item" @click="handleClickAddSVG('/assets/icons/square-5.svg')"><img src="/assets/icons/square-5.svg" alt=""></div>
                <div class="svglist__item" @click="handleClickAddSVG('/assets/icons/square-6.svg')"><img src="/assets/icons/square-6.svg" alt=""></div>
                <div class="svglist__item" @click="handleClickAddSVG('/assets/icons/square-7.svg')"><img src="/assets/icons/square-7.svg" alt=""></div>
                <div class="svglist__item" @click="handleClickAddSVG('/assets/icons/square-8.svg')"><img src="/assets/icons/square-8.svg" alt=""></div>
                <div class="svglist__item" @click="handleClickAddSVG('/assets/icons/square-9.svg')"><img src="/assets/icons/square-9.svg" alt=""></div>
                <div class="svglist__item" @click="handleClickAddSVG('/assets/icons/square-a.svg')"><img src="/assets/icons/square-a.svg" alt=""></div>
                <div class="svglist__item" @click="handleClickAddSVG('/assets/icons/square-b.svg')"><img src="/assets/icons/square-b.svg" alt=""></div>
                <div class="svglist__item" @click="handleClickAddSVG('/assets/icons/square-c.svg')"><img src="/assets/icons/square-c.svg" alt=""></div>
                <div class="svglist__item" @click="handleClickAddSVG('/assets/icons/square-d.svg')"><img src="/assets/icons/square-d.svg" alt=""></div>
                <div class="svglist__item" @click="handleClickAddSVG('/assets/icons/square-e.svg')"><img src="/assets/icons/square-e.svg" alt=""></div>
                <div class="svglist__item" @click="handleClickAddSVG('/assets/icons/square-f.svg')"><img src="/assets/icons/square-f.svg" alt=""></div>
                <div class="svglist__item" @click="handleClickAddSVG('/assets/icons/square-g.svg')"><img src="/assets/icons/square-g.svg" alt=""></div>
                <div class="svglist__item" @click="handleClickAddSVG('/assets/icons/square-h.svg')"><img src="/assets/icons/square-h.svg" alt=""></div>
                <div class="svglist__item" @click="handleClickAddSVG('/assets/icons/square-i.svg')"><img src="/assets/icons/square-i.svg" alt=""></div>
                <div class="svglist__item" @click="handleClickAddSVG('/assets/icons/square-j.svg')"><img src="/assets/icons/square-j.svg" alt=""></div>
                <div class="svglist__item" @click="handleClickAddSVG('/assets/icons/square-k.svg')"><img src="/assets/icons/square-k.svg" alt=""></div>
                <div class="svglist__item" @click="handleClickAddSVG('/assets/icons/square-l.svg')"><img src="/assets/icons/square-l.svg" alt=""></div>
                <div class="svglist__item" @click="handleClickAddSVG('/assets/icons/square-m.svg')"><img src="/assets/icons/square-m.svg" alt=""></div>
                <div class="svglist__item" @click="handleClickAddSVG('/assets/icons/square-n.svg')"><img src="/assets/icons/square-n.svg" alt=""></div>
                <div class="svglist__item" @click="handleClickAddSVG('/assets/icons/square-o.svg')"><img src="/assets/icons/square-o.svg" alt=""></div>
                <div class="svglist__item" @click="handleClickAddSVG('/assets/icons/square-p.svg')"><img src="/assets/icons/square-p.svg" alt=""></div>
                <div class="svglist__item" @click="handleClickAddSVG('/assets/icons/square-q.svg')"><img src="/assets/icons/square-q.svg" alt=""></div>
                <div class="svglist__item" @click="handleClickAddSVG('/assets/icons/square-r.svg')"><img src="/assets/icons/square-r.svg" alt=""></div>
                <div class="svglist__item" @click="handleClickAddSVG('/assets/icons/square-s.svg')"><img src="/assets/icons/square-s.svg" alt=""></div>
                <div class="svglist__item" @click="handleClickAddSVG('/assets/icons/square-t.svg')"><img src="/assets/icons/square-t.svg" alt=""></div>
                <div class="svglist__item" @click="handleClickAddSVG('/assets/icons/square-u.svg')"><img src="/assets/icons/square-u.svg" alt=""></div>
                <div class="svglist__item" @click="handleClickAddSVG('/assets/icons/square-v.svg')"><img src="/assets/icons/square-v.svg" alt=""></div>
                <div class="svglist__item" @click="handleClickAddSVG('/assets/icons/square-w.svg')"><img src="/assets/icons/square-w.svg" alt=""></div>
                <div class="svglist__item" @click="handleClickAddSVG('/assets/icons/square-x.svg')"><img src="/assets/icons/square-x.svg" alt=""></div>
                <div class="svglist__item" @click="handleClickAddSVG('/assets/icons/square-y.svg')"><img src="/assets/icons/square-y.svg" alt=""></div>
                <div class="svglist__item" @click="handleClickAddSVG('/assets/icons/square-z.svg')"><img src="/assets/icons/square-z.svg" alt=""></div>
              </div>
              <div class="svglist__group">
                <div class="svglist__item" @click="handleClickAddSVG('/assets/icons/crow.svg')"><img src="/assets/icons/crow.svg" alt=""></div>
                <div class="svglist__item" @click="handleClickAddSVG('/assets/icons/fish.svg')"><img src="/assets/icons/fish.svg" alt=""></div>
                <div class="svglist__item" @click="handleClickAddSVG('/assets/icons/crab.svg')"><img src="/assets/icons/crab.svg" alt=""></div>
                <div class="svglist__item" @click="handleClickAddSVG('/assets/icons/shrimp.svg')"><img src="/assets/icons/shrimp.svg" alt=""></div>
                <div class="svglist__item" @click="handleClickAddSVG('/assets/icons/squid.svg')"><img src="/assets/icons/squid.svg" alt=""></div>

                <div class="svglist__item" @click="handleClickAddSVG('/assets/icons/car-side.svg')"><img src="/assets/icons/car-side.svg" alt=""></div>
                <div class="svglist__item" @click="handleClickAddSVG('/assets/icons/forklift.svg')"><img src="/assets/icons/forklift.svg" alt=""></div>
                <div class="svglist__item" @click="handleClickAddSVG('/assets/icons/truck-fast.svg')"><img src="/assets/icons/truck-fast.svg" alt=""></div>
                <div class="svglist__item" @click="handleClickAddSVG('/assets/icons/truck-tow.svg')"><img src="/assets/icons/truck-tow.svg" alt=""></div>
                <div class="svglist__item" @click="handleClickAddSVG('/assets/icons/truck.svg')"><img src="/assets/icons/truck.svg" alt=""></div>
                <div class="svglist__item" @click="handleClickAddSVG('/assets/icons/bomb.svg')"><img src="/assets/icons/bomb.svg" alt=""></div>
                <div class="svglist__item" @click="handleClickAddSVG('/assets/icons/skull.svg')"><img src="/assets/icons/skull.svg" alt=""></div>
                <div class="svglist__item" @click="handleClickAddSVG('/assets/icons/skull-crossbones.svg')"><img src="/assets/icons/skull-crossbones.svg" alt=""></div>

                <div class="svglist__item" @click="handleClickAddSVG('/assets/icons/hammer.svg')"><img src="/assets/icons/hammer.svg" alt=""></div>
                <div class="svglist__item" @click="handleClickAddSVG('/assets/icons/shovel.svg')"><img src="/assets/icons/shovel.svg" alt=""></div>
                <div class="svglist__item" @click="handleClickAddSVG('/assets/icons/wrench.svg')"><img src="/assets/icons/wrench.svg" alt=""></div>
                <div class="svglist__item" @click="handleClickAddSVG('/assets/icons/thumbs-down.svg')"><img src="/assets/icons/thumbs-down.svg" alt=""></div>
                <div class="svglist__item" @click="handleClickAddSVG('/assets/icons/thumbs-up.svg')"><img src="/assets/icons/thumbs-up.svg" alt=""></div>

                <div class="svglist__item" @click="handleClickAddSVG('/assets/icons/flag-pennant.svg')"><img src="/assets/icons/flag-pennant.svg" alt=""></div>
                <div class="svglist__item" @click="handleClickAddSVG('/assets/icons/flag-swallowtail.svg')"><img src="/assets/icons/flag-swallowtail.svg" alt=""></div>
                <div class="svglist__item" @click="handleClickAddSVG('/assets/icons/flag.svg')"><img src="/assets/icons/flag.svg" alt=""></div>
                <div class="svglist__item" @click="handleClickAddSVG('/assets/icons/house-chimney.svg')"><img src="/assets/icons/house-chimney.svg" alt=""></div>
                <div class="svglist__item" @click="handleClickAddSVG('/assets/icons/house.svg')"><img src="/assets/icons/house.svg" alt=""></div>
                <div class="svglist__item" @click="handleClickAddSVG('/assets/icons/camera-cctv.svg')"><img src="/assets/icons/camera-cctv.svg" alt=""></div>
                <div class="svglist__item" @click="handleClickAddSVG('/assets/icons/camera.svg')"><img src="/assets/icons/camera.svg" alt=""></div>
                <div class="svglist__item" @click="handleClickAddSVG('/assets/icons/crown.svg')"><img src="/assets/icons/crown.svg" alt=""></div>
                <div class="svglist__item" @click="handleClickAddSVG('/assets/icons/microphone-lines.svg')"><img src="/assets/icons/microphone-lines.svg" alt=""></div>
                <div class="svglist__item" @click="handleClickAddSVG('/assets/icons/phone-volume.svg')"><img src="/assets/icons/phone-volume.svg" alt=""></div>
                <div class="svglist__item" @click="handleClickAddSVG('/assets/icons/plug.svg')"><img src="/assets/icons/plug.svg" alt=""></div>
                <div class="svglist__item" @click="handleClickAddSVG('/assets/icons/key-skeleton.svg')"><img src="/assets/icons/key-skeleton.svg" alt=""></div>
                <div class="svglist__item" @click="handleClickAddSVG('/assets/icons/shield-keyhole.svg')"><img src="/assets/icons/shield-keyhole.svg" alt=""></div>
                <div class="svglist__item" @click="handleClickAddSVG('/assets/icons/siren-on.svg')"><img src="/assets/icons/siren-on.svg" alt=""></div>

                <div class="svglist__item" @click="handleClickAddSVG('/assets/icons/star.svg')"><img src="/assets/icons/star.svg" alt=""></div>
                <div class="svglist__item" @click="handleClickAddSVG('/assets/icons/tag.svg')"><img src="/assets/icons/tag.svg" alt=""></div>
              </div>
              <div class="svglist__group">
                <div class="svglist__item" @click="handleClickAddSVG('/assets/icons/user-helmet-safety.svg')"><img src="/assets/icons/user-helmet-safety.svg" alt=""></div>
                <div class="svglist__item" @click="handleClickAddSVG('/assets/icons/user-large.svg')"><img src="/assets/icons/user-large.svg" alt=""></div>
                <div class="svglist__item" @click="handleClickAddSVG('/assets/icons/user-pilot.svg')"><img src="/assets/icons/user-pilot.svg" alt=""></div>
                <div class="svglist__item" @click="handleClickAddSVG('/assets/icons/user-secret.svg')"><img src="/assets/icons/user-secret.svg" alt=""></div>
                <div class="svglist__item" @click="handleClickAddSVG('/assets/icons/user-tie-hair-long.svg')"><img src="/assets/icons/user-tie-hair-long.svg" alt=""></div>
                <div class="svglist__item" @click="handleClickAddSVG('/assets/icons/user-tie-hair.svg')"><img src="/assets/icons/user-tie-hair.svg" alt=""></div>
                <div class="svglist__item" @click="handleClickAddSVG('/assets/icons/user-tie.svg')"><img src="/assets/icons/user-tie.svg" alt=""></div>
                <div class="svglist__item" @click="handleClickAddSVG('/assets/icons/restroom-simple.svg')"><img src="/assets/icons/restroom-simple.svg" alt=""></div>
              </div>
              <div class="svglist__group">
                <div class="svglist__item" @click="handleClickAddSVG('/assets/icons/triangle-exclamation.svg')"><img src="/assets/icons/triangle-exclamation.svg" alt=""></div>
                <div class="svglist__item" @click="handleClickAddSVG('/assets/icons/circle-question.svg')"><img src="/assets/icons/circle-question.svg" alt=""></div>
                <div class="svglist__item" @click="handleClickAddSVG('/assets/icons/octagon-xmark.svg')"><img src="/assets/icons/octagon-xmark.svg" alt=""></div>
                <div class="svglist__item" @click="handleClickAddSVG('/assets/icons/message-check.svg')"><img src="/assets/icons/message-check.svg" alt=""></div>
                <div class="svglist__item" @click="handleClickAddSVG('/assets/icons/message-dots.svg')"><img src="/assets/icons/message-dots.svg" alt=""></div>
                <div class="svglist__item" @click="handleClickAddSVG('/assets/icons/message-exclamation.svg')"><img src="/assets/icons/message-exclamation.svg" alt=""></div>
                <div class="svglist__item" @click="handleClickAddSVG('/assets/icons/message-question.svg')"><img src="/assets/icons/message-question.svg" alt=""></div>
                <div class="svglist__item" @click="handleClickAddSVG('/assets/icons/message-heart.svg')"><img src="/assets/icons/message-heart.svg" alt=""></div>
                <div class="svglist__item" @click="handleClickAddSVG('/assets/icons/message-image.svg')"><img src="/assets/icons/message-image.svg" alt=""></div>
              </div>
            </div>
          </div>
          <div
            class="icon -clip"
            :class="{
              '-active': isClipShowFlag,
              '-disabled': isClipArea || !setting.base64Url
            }"
          >
            <button class="cliplist" @click="isClipShowFlag = !isClipShowFlag; isSVGShowFlag = false;">
              <i class="fa-solid fa-crop"></i>
            </button>
            <div class="cliplist-content" :class="{'-show': isClipShowFlag == true}">
              <button class="size1:1" @click="clip('1:1');"><img src="/assets/icons/1_1.svg" alt="1:1"></button>
              <button class="size6:4" @click="clip('3:2');"><img src="/assets/icons/3_2.svg" alt="3:2"></button>
              <button class="size4:6" @click="clip('2:3');"><img src="/assets/icons/2_3.svg" alt="2:3"></button>
              <button class="size16:9" @click="clip('16:9');"><img src="/assets/icons/16_9.svg" alt="16:9"></button>
              <button class="size9:16" @click="clip('9:16');"><img src="/assets/icons/9_16.svg" alt="9:16"></button>
            </div>
          </div>
          <button
            class="icon sidemenu-button_ button_"
            :class="{
              '-active': currentStatus == 'mosaic',
              '-disabled': !setting.base64Url
            }"
            @click="currentStatus = 'mosaic'"
          >
            <i class="fa-solid fa-chess-board"></i>
            モザイク処理
          </button>
          <!-- <hr style="margin-top: 20px; margin-bottom: 20px; border-top: 1px solid #ddd;"> -->
          <button
            class="icon sidemenu-button_ button_"
            :class="{
              '-disabled': !setting.base64Url
            }"
            @click="isBlackbordModalOpen = true"
          >
            <i class="fa-solid fa-chalkboard-user"></i>
            電子黒板レイアウト
          </button>
          <!-- <hr style="margin-top: 20px; margin-bottom: 20px; border-top: 1px solid #ddd;"> -->
          <button
            v-if="!isInitImageSet"
            class="icon sidemenu-button_ button_"
            @click="isImageSetModalOpen = true"
          >
            <i class="fa-solid fa-image"></i>
            背景画像設定
          </button>
          <button class="icon -spacer"></button>
          <button
            v-if="!isInitImageSet"
            class="icon -save sidemenu-button_ button_"
            :class="{
              '-disabled': !setting.base64Url
            }"
            @click="download"
          >
            <i class="fa-solid fa-download"></i>
            <!-- <i class="fa-solid fa-download"></i> -->
            画像保存（元画像 + 編集画像）
          </button>
        </div>
        <!-- <p style="color: #000;font-size: 12px; font-weight: 500; margin-top: 10px;">※localStorageで擬似的に保存を実装しています。<br>データ保存後にリロード・再編集後に復元ボタン押下で動作を確認いただけます。</p> -->
      </div>
    </div>
  </div>
  <div
    class="modal"
    v-show="isBlackbordModalOpen"
  >
    <div class="modal-wrapper">
      <div class="modal-header">電子黒板</div>
      <div class="modal-content">
        <div class="blackboard" :class="{'-isBlackboardPrint': isBlackboardPrint }">
          <div id="blackboard">
            <div class="blackboard-head">
              <dl>
                <dt>お客様名</dt>
                <dd>{{ blackboardHeader.user_name }}</dd>
              </dl>
              <dl>
                <dt>現場名</dt>
                <dd>{{ blackboardHeader.site_name }}</dd>
              </dl>
              <dl v-if="blackboardHeader.site_sub_name">
                <dt>作業所</dt>
                <dd>{{ blackboardHeader.site_sub_name }}</dd>
              </dl>
              <dl>
                <dt>入庫日</dt>
                <dd>{{ blackboardHeader.unloading_date}}</dd>
              </dl>
              <dl>
                <dt>伝票No.</dt>
                <dd>{{ blackboardHeader.return_slipCD }}</dd>
              </dl>
            </div>
            <div class="blackboard-body">
  <pre class="preview">{{ blackboardBody }}</pre>
              <textarea v-model="blackboardBody"></textarea>
            </div>
          </div>
        </div>

        <div class="blackboard-select" v-if="blackboardRentRepairDetails.length >= 2">
          <p class="blackboard-select-label">修理内容一覧</p>
          <select
            v-model="blackboardRentRepairDetail"
            @change="setBlackboardBody()"
          >
            <option value="">選択してください</option>
            <option
              v-for="(item, index) in blackboardRentRepairDetails"
              :key="index"
              :value="item.repair_detail">{{ item.kizai_name }}</option>
          </select>
        </div>
      </div>
      <div class="modal-footer">
        <button
          class="button -save"
          @click="saveBlackboard()"
        >保存</button>
        <button
          class="button -cancel"
          @click="isBlackbordModalOpen = false"
        >キャンセル</button>
      </div>
    </div>
  </div>
  <div
    class="modal"
    v-show="isImageSetModalOpen"
  >
    <div class="modal-wrapper">
      <div class="modal-header">画像設定</div>
      <div class="modal-content">
        <div class="" v-if="setting.imageurl || setting.base64Url">
          <div class="block-keepout-drawing-image">
            <img :src="setting.imageurl" alt="" />
          </div>
          <div class="button_wrap -left">
            <input
              type="file"
              id="fileupload"
              @change="onChangeFileUpload($event)"
              style="display: none;"
            />
            <label class="button -weak -mid" for="fileupload">
              画像変更
            </label>
          </div>
        </div>
        <div class="" v-else>
          <div class="fileUpload -wide">
            <div
              class="-uploader"
              @dragover.prevent
              @drop.prevent="onDropOntoUploader($event)"
            >
              <input
                type="file"
                id="fileupload"
                @change="onChangeFileUpload($event)"
                style="display: none"
              />
              <label
                class="button -weak -mid fileArea"
                for="fileupload"
              >
                ファイルを選択
              </label>
              <span class="-text"
                >またはファイルをドラッグ＆ドロップして添付</span
              ><br>
              <span class="-notice"
                >※対応形式：JPG, PNG 上限容量：10MB</span
              >
            </div>
            {{ setting.imageValideteErrorMsg }}
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button
          class="button -cancel"
          @click="isImageSetModalOpen = false"
        >閉じる</button>
      </div>
    </div>
  </div>
  <div
    class="modal"
    v-show="isAddTemplateStringOpen"
  >
    <div class="modal-wrapper">
      <div class="modal-header">テンプレート文字列の追加</div>
      <div class="modal-content">
        <div class="button-block">
          <button class="button" @click="currentStatus = 'templateString'; isAddTemplateStringOpen = false; currentAddObject = 'number'">数字追加</button>
          <button class="button" @click="currentStatus = 'templateString'; isAddTemplateStringOpen = false; currentAddObject = ''">丸囲み数字追加</button>
        </div>
      </div>
      <div class="modal-footer">
        <button
          class="button -cancel"
          @click="isAddTemplateStringOpen = false"
        >閉じる</button>
      </div>
    </div>
  </div>
  <div
    class="modal"
    v-show="isAddRectangleOpen"
  >
    <div class="modal-wrapper">
      <div class="modal-header">矩形追加</div>
      <div class="modal-content">
        <div class="button-block">
          <button class="button" @click="currentStatus = 'rectangle';isAddRectangleOpen = false; currentAddObject = 'square'">四角</button>
          <button class="button" @click="currentStatus = 'rectangle';isAddRectangleOpen = false; currentAddObject = 'squareStroke'">四角（線のみ）</button>
          <button class="button" @click="currentStatus = 'rectangle';isAddRectangleOpen = false; currentAddObject = 'circle'">丸</button>
          <button class="button" @click="currentStatus = 'rectangle';isAddRectangleOpen = false; currentAddObject = 'circleStroke'">丸（線のみ）</button>
          <button class="button" @click="arrow();isAddRectangleOpen = false;">矢印</button>
        </div>
      </div>
      <div class="modal-footer">
        <button
          class="button -cancel"
          @click="isAddRectangleOpen = false"
        >閉じる</button>
      </div>
    </div>
  </div>
  <div class="modal"
    v-show="notificationMsg"
  >
    <div class="modal-wrapper">
      <div class="modal-content">
        <div class="modal-body">
          <div style="white-space: pre-line;">{{ notificationMsg }}</div>
        </div>
        <div class="modal-foot" v-if="notificationClose">
          <div class="button-group">
            <button class="button -w130" @click="notificationMsg = '';">閉じる</button>
          </div>
        </div>
      </div>
    </div>
  </div>


  <div class="loading"
    :class="{
      'isLoading': isLoading
    }"
  >
    <div class="loader">
      <div class="one"></div>
      <div class="two"></div>
      <div class="three"></div>
      <div class="four"></div>
    </div>
  </div>
  <div class="tempDummyIcon" :style="dummyIconStyle" v-show="dummyIcon">
    <img :src="dummyIcon" alt="">
  </div>
</template>

<script setup>
import { fabric } from "fabric-with-gestures"
import { ref, computed, onMounted, watch } from "vue";
import imageCompression from 'browser-image-compression';
// import html2canvas from 'html2canvas';
import domtoimage from 'dom-to-image';

  const canvas = computed(() => new fabric.Canvas("canvas", {
    preserveObjectStacking: true,
  }));
  const isClipArea = ref(false)

  const notificationMsg = ref("");
  const notificationClose = ref(false);

  const isBlackbordModalOpen = ref(false);
  const isImageSetModalOpen = ref(false);
  const isAddTemplateStringOpen = ref(false);
  const isAddRectangleOpen = ref(false);
  const isAddStringFlag = ref(false);
  const isSVGShowFlag = ref(false);
  const isClipShowFlag = ref(false);
  const isColorShowFlag = ref(false);
  const isLongPress = ref(false);

  let history = [];
  let historyIndex = ref(-1);
  let isUndoRedoOperation = false;

  const dummyIconStyle = ref(null);
  const dummyIcon = ref(null);

  const lastPosX = ref(null);
  const lastPosY = ref(null);
  const lastScale = ref(1);
  const lastDistance = ref(0);
  const isDragging = ref(false);
  const isInitImageSet = ref(false);
  const lastAngle = ref(0);

  const isLoading = ref(false);
  const setting = ref({
    image: null,
    imageWidth: 0,
    imageHeight: 0,
    imageurl: "",
    base64Url: "",
    imageValideteErrorMsg: "",
  });

  const textColor = ref('#000000');
  const complementaryColor = computed(() => getComplementary(textColor.value));
  const URLPARAMS = ref({});

  let line,
      arrowHead,
      mouseDownPoint,
      linePath,
      blackboardHeader = ref({}),
      blackboardBody = ref(''),
      blackboardRentRepairDetail = ref(''),
      blackboardRentRepairDetails = ref([]),
      currentStatus = ref('select'),
      currentAddObject = ref(''),
      prevStatus = ref('select'),
      mouseDown = false,
      isEditing = false;

  onMounted(() => {
    // URLパラメータを取得しデコードしてからkey=valueの形式でconst:queryに格納
    URLPARAMS.value = decodeURIComponent(window.location.search.substring(1))
      .split('&')
      .reduce((acc, cur) => {
        const [key, value] = cur.split('=');
        return { ...acc, [key]: value };
      }, {});
    // query.qの内容はJSON形式の文字列なのでJSON.parseでオブジェクトに変換
    if (URLPARAMS.value.q) {
      URLPARAMS.value.q = JSON.parse(URLPARAMS.value.q);
    }

    if(!setting.value.base64Url) {
      isImageSetModalOpen.value = true;
    }
    if (URLPARAMS.value.initviewimage) {
      isImageSetModalOpen.value = false;
      isInitImageSet.value = true;
    }
    let contentHeight = document.querySelector('.container .content').clientHeight;
    let contentWidth = document.querySelector('.container .content').clientWidth;

    canvas.value.setHeight(contentHeight);
    canvas.value.setWidth(contentWidth);
    canvas.value.renderAll();


    // デコードする関数
    function decodeURIComponentSafe(encodedURI) {
      try {
        return decodeURIComponent(encodedURI);
      } catch (e) {
        return encodedURI;
      }
    }

    if (URLPARAMS.value.q) {
      blackboardHeader.value = {
        user_name: URLPARAMS.value.q?.user_name ? decodeURIComponentSafe(URLPARAMS.value.q.user_name) : '',
        site_name: URLPARAMS.value.q?.site_name ? decodeURIComponentSafe(URLPARAMS.value.q.site_name) : '',
        site_sub_name: URLPARAMS.value.q?.site_sub_name ? decodeURIComponentSafe(URLPARAMS.value.q.site_sub_name) : '',
        unloading_date: URLPARAMS.value.q?.unloading_date ? decodeURIComponentSafe(URLPARAMS.value.q.unloading_date) : '',
        return_slipCD: URLPARAMS.value.q?.return_slipCD ? decodeURIComponentSafe(URLPARAMS.value.q.return_slipCD) : '',
      }
      blackboardBody.value = URLPARAMS.value.q?.rentRepairDetail && URLPARAMS.value.q?.rentRepairDetail.length == 1 ? decodeURIComponentSafe(URLPARAMS.value.q.rentRepairDetail[0].repair_detail) : '';
      blackboardRentRepairDetails.value = URLPARAMS.value.q?.rentRepairDetail && URLPARAMS.value.q?.rentRepairDetail.length >= 1 ? URLPARAMS.value.q.rentRepairDetail : [];

      blackboardRentRepairDetails.value.forEach(item => {
        item.kizai_name = decodeURIComponentSafe(item.kizai_name);
        item.repair_detail = decodeURIComponentSafe(item.repair_detail);
      });
    }


    // リサイズイベント終了後に再度canvasのサイズを設定する
    let resizeTimer;
    window.addEventListener('resize', () => {
      clearTimeout(resizeTimer);
      resizeTimer = setTimeout(() => {
        let contentHeight = document.querySelector('.container .content').clientHeight;
        let contentWidth = document.querySelector('.container .content').clientWidth;
        canvas.value.setHeight(contentHeight);
        canvas.value.setWidth(contentWidth);
        canvas.value.renderAll();
      }, 200);
    });

    const getTouchDistance = (e) => {
      var touch1 = e.touches[0];
      var touch2 = e.touches[1];
      return Math.sqrt(
        (touch1.clientX - touch2.clientX) * (touch1.clientX - touch2.clientX) +
        (touch1.clientY - touch2.clientY) * (touch1.clientY - touch2.clientY)
      );
    }

    // コーナーの表示調整
    // 複製アイコンの追加
    let copyIcon = "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI3NzIiIGhlaWdodD0iNzcyIiB2aWV3Qm94PSIwIDAgNzcyIDc3MiI+CiAgPGcgaWQ9IuOCsOODq+ODvOODl18xOTUiIGRhdGEtbmFtZT0i44Kw44Or44O844OXIDE5NSIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTEzNDUgMTU2MikiPgogICAgPHJlY3QgaWQ9IumVt+aWueW9ol84MiIgZGF0YS1uYW1lPSLplbfmlrnlvaIgODIiIHdpZHRoPSI3NzIiIGhlaWdodD0iNzcyIiByeD0iNzUiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDEzNDUgLTE1NjIpIiBmaWxsPSIjZmZmIi8+CiAgICA8cGF0aCBpZD0i6ZW35pa55b2iXzgyXy1f44Ki44Km44OI44Op44Kk44OzIiBkYXRhLW5hbWU9IumVt+aWueW9oiA4MiAtIOOCouOCpuODiOODqeOCpOODsyIgZD0iTTc1LDIwQTU1LjA2Miw1NS4wNjIsMCwwLDAsMjAsNzVWNjk3YTU1LjA2Miw1NS4wNjIsMCwwLDAsNTUsNTVINjk3YTU1LjA2Miw1NS4wNjIsMCwwLDAsNTUtNTVWNzVhNTUuMDYyLDU1LjA2MiwwLDAsMC01NS01NUg3NU03NSwwSDY5N2E3NSw3NSwwLDAsMSw3NSw3NVY2OTdhNzUsNzUsMCwwLDEtNzUsNzVINzVBNzUsNzUsMCwwLDEsMCw2OTdWNzVBNzUsNzUsMCwwLDEsNzUsMFoiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDEzNDUgLTE1NjIpIiBmaWxsPSIjNzc3Ii8+CiAgICA8cGF0aCBpZD0iY29weS1zb2xpZCIgZD0iTTIwOCwwSDMzMi4xQTQ4LDQ4LDAsMCwxLDM2NiwxNC4xTDQzMy45LDgyQTQ4LDQ4LDAsMCwxLDQ0OCwxMTUuOVYzMzZhNDguMDEyLDQ4LjAxMiwwLDAsMS00OCw0OEgyMDhhNDguMDEyLDQ4LjAxMiwwLDAsMS00OC00OFY0OEE0OC4wMTIsNDguMDEyLDAsMCwxLDIwOCwwWk00OCwxMjhoODB2NjRINjRWNDQ4SDI1NlY0MTZoNjR2NDhhNDguMDEyLDQ4LjAxMiwwLDAsMS00OCw0OEg0OEE0OC4wMTIsNDguMDEyLDAsMCwxLDAsNDY0VjE3NkE0OC4wMTIsNDguMDEyLDAsMCwxLDQ4LDEyOFoiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDE1MDcgLTE0MzIpIiBmaWxsPSIjNzc3Ii8+CiAgPC9nPgo8L3N2Zz4K";
    let copyIconImg = document.createElement('img');
    copyIconImg.src = copyIcon;

    fabric.Object.prototype.transparentCorners = false;
    fabric.Object.prototype.cornerColor = '#777';
    fabric.Object.prototype.cornerSize = 10;
    fabric.Object.prototype.touchCornerSize = 24;
    fabric.Object.prototype.cornerStyle = 'circle';
    // fabric.Object.prototype.cornerSize = 24;
    fabric.Object.prototype.controls.clone = new fabric.Control({
      x: 0.5,
      y: -0.5,
      offsetX: 0,
      offsetY: -35,
      cursorStyle: 'pointer',
      mouseUpHandler: copyObject,
      render: renderIcon,
      cornerSize: 24,
      touchCornerSize: 24
    });

    function renderCustomIcon(ctx, left, top, styleOverride, fabricObject, iconImg) {
      let size = this.cornerSize;
      let activeObject = canvas.value.getActiveObject();
      if (!activeObject) return false;
      if (activeObject.type === 'activeSelection') {
          activeObject.forEachObject(obj => {
              if(obj.label === 'clipArea') {
                  return false;
              }
          });
      } else {
          if(activeObject.label === 'clipArea') {
              return false;
          }
      }
      ctx.save();
      ctx.translate(left, top);
      ctx.rotate(fabric.util.degreesToRadians(fabricObject.angle));
      ctx.drawImage(iconImg, -size/2, -size/2, size, size);
      ctx.restore();
    }

    function renderIcon(ctx, left, top, styleOverride, fabricObject) {
      renderCustomIcon.call(this, ctx, left, top, styleOverride, fabricObject, copyIconImg);
    }

    // deleteアイコンの追加
    let deleteIcon = "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNjQiIGhlaWdodD0iMjY0IiB2aWV3Qm94PSIwIDAgMjY0IDI2NCI+CiAgPGcgaWQ9IuOCsOODq+ODvOODl18xIiBkYXRhLW5hbWU9IuOCsOODq+ODvOODlyAxIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtMjU2IC0yNDkpIj4KICAgIDxyZWN0IGlkPSLplbfmlrnlvaJfMSIgZGF0YS1uYW1lPSLplbfmlrnlvaIgMSIgd2lkdGg9IjI2NCIgaGVpZ2h0PSIyNjQiIHJ4PSIyOCIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMjU2IDI0OSkiIGZpbGw9IiNmZmYiLz4KICAgIDxwYXRoIGlkPSLplbfmlrnlvaJfMV8tX+OCouOCpuODiOODqeOCpOODsyIgZGF0YS1uYW1lPSLplbfmlrnlvaIgMSAtIOOCouOCpuODiOODqeOCpOODsyIgZD0iTTI4LDdBMjEuMDI0LDIxLjAyNCwwLDAsMCw3LDI4VjIzNmEyMS4wMjQsMjEuMDI0LDAsMCwwLDIxLDIxSDIzNmEyMS4wMjQsMjEuMDI0LDAsMCwwLDIxLTIxVjI4QTIxLjAyNCwyMS4wMjQsMCwwLDAsMjM2LDdIMjhtMC03SDIzNmEyOCwyOCwwLDAsMSwyOCwyOFYyMzZhMjgsMjgsMCwwLDEtMjgsMjhIMjhBMjgsMjgsMCwwLDEsMCwyMzZWMjhBMjgsMjgsMCwwLDEsMjgsMFoiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDI1NiAyNDkpIiBmaWxsPSIjNzc3Ii8+CiAgICA8cGF0aCBpZD0idHJhc2gtc29saWQiIGQ9Ik00Ni43LDYuMTE0bC0yLjQ4Nyw0Ljk0SDExLjA1NGExMS4wNTQsMTEuMDU0LDAsMCwwLDAsMjIuMTA3SDE0My43YTExLjA1NCwxMS4wNTQsMCwxLDAsMC0yMi4xMDdIMTEwLjUzNmwtMi40ODctNC45NEExMS4wMDksMTEuMDA5LDAsMCwwLDk4LjE3LDBINTYuNTgxQTExLjAwOSwxMS4wMDksMCwwLDAsNDYuNyw2LjExNFptOTcsMzguMUgxMS4wNTRsNy4zMjMsMTE3LjFhMTYuNTkyLDE2LjU5MiwwLDAsMCwxNi41NDYsMTUuNTQ0aDg0LjkwNmExNi41OTIsMTYuNTkyLDAsMCwwLDE2LjU0Ni0xNS41NDRaIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgzMTEgMjkyKSIgZmlsbD0iIzc3NyIvPgogIDwvZz4KPC9zdmc+Cg==";
    let deleteIconImg = document.createElement('img');
    deleteIconImg.src = deleteIcon;

    fabric.Object.prototype.controls.deleteControl = new fabric.Control({
      x: 0.5,
      y: -0.5,
      offsetX: -30,
      offsetY: -35,
      cursorStyle: 'pointer',
      mouseUpHandler: deleteObject,
      render: renderDeleteIcon,
      cornerSize: 24,
      touchCornerSize: 24
    });
    function renderDeleteIcon(ctx, left, top, styleOverride, fabricObject) {
      renderCustomIcon.call(this, ctx, left, top, styleOverride, fabricObject, deleteIconImg);
    }

    // 最前面アイコンの追加
    let zindexFrontIcon = "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNjQiIGhlaWdodD0iMjY0IiB2aWV3Qm94PSIwIDAgMjY0IDI2NCI+PGcgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTEyNDQgLTI1MSkiPjxyZWN0IHdpZHRoPSIyNjQiIGhlaWdodD0iMjY0IiByeD0iMjgiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDEyNDQgMjUxKSIgZmlsbD0iI2ZmZiIvPjxwYXRoIGQ9Ik0yOCw3QTIxLjAyNCwyMS4wMjQsMCwwLDAsNywyOFYyMzZhMjEuMDI0LDIxLjAyNCwwLDAsMCwyMSwyMUgyMzZhMjEuMDI0LDIxLjAyNCwwLDAsMCwyMS0yMVYyOEEyMS4wMjQsMjEuMDI0LDAsMCwwLDIzNiw3SDI4bTAtN0gyMzZhMjgsMjgsMCwwLDEsMjgsMjhWMjM2YTI4LDI4LDAsMCwxLTI4LDI4SDI4QTI4LDI4LDAsMCwxLDAsMjM2VjI4QTI4LDI4LDAsMCwxLDI4LDBaIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgxMjQ0IDI1MSkiIGZpbGw9IiM3NzciLz48cGF0aCBkPSJNNTkuMjgzLDM1LjcxNGExMS40MiwxMS40MiwwLDAsMC0xNi44NTcsMEwxMSw3MEExMS40MjYsMTEuNDI2LDAsMSwwLDI3Ljg1NCw4NS40MjlMMzkuNDI2LDcyLjgyMXYxMDcuNzVhMTEuNDI5LDExLjQyOSwwLDEsMCwyMi44NTcsMFY3Mi44MjFMNzMuODU0LDg1LjQ2NEExMS40MjYsMTEuNDI2LDAsMSwwLDkwLjcxMSw3MC4wMzZMNTkuMjgzLDM1Ljc1Wk0xMTkuNDI2LDE5MmgxMS40MjlhMTEuNDI5LDExLjQyOSwwLDEsMCwwLTIyLjg1N0gxMTkuNDI2YTExLjQyOSwxMS40MjksMCwwLDAsMCwyMi44NTdabTAtNDUuNzE0aDM0LjI4NmExMS40MjksMTEuNDI5LDAsMCwwLDAtMjIuODU3SDExOS40MjZhMTEuNDI5LDExLjQyOSwwLDAsMCwwLDIyLjg1N1ptMC00NS43MTRoNTcuMTQzYTExLjQyOSwxMS40MjksMCwxLDAsMC0yMi44NTdIMTE5LjQyNmExMS40MjksMTEuNDI5LDAsMCwwLDAsMjIuODU3Wm0wLTQ1LjcxNGg4MGExMS40MjksMTEuNDI5LDAsMSwwLDAtMjIuODU3aC04MGExMS40MjksMTEuNDI5LDAsMSwwLDAsMjIuODU3WiIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMTI2Ny4wMDQgMjcxKSIgZmlsbD0iIzc3NyIvPjwvZz48L3N2Zz4=";
    let zindexFrontIconImg = document.createElement('img');
    zindexFrontIconImg.src = zindexFrontIcon;

    fabric.Object.prototype.controls.zindexFrontControl = new fabric.Control({
      x: 0.5,
      y: -0.5,
      offsetX: -30,
      offsetY: -65,
      cursorStyle: 'pointer',
      mouseUpHandler: handleClickSetActiveObjectZindexFront,
      render: renderZindexFrontIcon,
      cornerSize: 24,
      touchCornerSize: 24
    });

    function renderZindexFrontIcon(ctx, left, top, styleOverride, fabricObject) {
      renderCustomIcon.call(this, ctx, left, top, styleOverride, fabricObject, zindexFrontIconImg);
    }

    // 最背面アイコンの追加
    let zindexBackIcon = "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNjQiIGhlaWdodD0iMjY0IiB2aWV3Qm94PSIwIDAgMjY0IDI2NCI+PGcgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTkxOCAtMjUxKSI+PHJlY3Qgd2lkdGg9IjI2NCIgaGVpZ2h0PSIyNjQiIHJ4PSIyOCIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoOTE4IDI1MSkiIGZpbGw9IiNmZmYiLz48cGF0aCBkPSJNMjgsN0EyMS4wMjQsMjEuMDI0LDAsMCwwLDcsMjhWMjM2YTIxLjAyNCwyMS4wMjQsMCwwLDAsMjEsMjFIMjM2YTIxLjAyNCwyMS4wMjQsMCwwLDAsMjEtMjFWMjhBMjEuMDI0LDIxLjAyNCwwLDAsMCwyMzYsN0gyOG0wLTdIMjM2YTI4LDI4LDAsMCwxLDI4LDI4VjIzNmEyOCwyOCwwLDAsMS0yOCwyOEgyOEEyOCwyOCwwLDAsMSwwLDIzNlYyOEEyOCwyOCwwLDAsMSwyOCwwWiIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoOTE4IDI1MSkiIGZpbGw9IiM3NzciLz48cGF0aCBkPSJNNTkuMjgzLDE4OC4yODZhMTEuNDIsMTEuNDIsMCwwLDEtMTYuODU3LDBMMTEsMTU0YTExLjQyNiwxMS40MjYsMCwwLDEsMTYuODU3LTE1LjQyOWwxMS41NzEsMTIuNjA3VjQzLjQyOWExMS40MjksMTEuNDI5LDAsMSwxLDIyLjg1NywwdjEwNy43NWwxMS41NzEtMTIuNjQzYTExLjQyNiwxMS40MjYsMCwxLDEsMTYuODU3LDE1LjQyOUw1OS4yODMsMTg4LjI1Wk0xMTkuNDI2LDE5MmExMS40MjksMTEuNDI5LDAsMCwxLDAtMjIuODU3aDExLjQyOWExMS40MjksMTEuNDI5LDAsMSwxLDAsMjIuODU3Wm0wLTQ1LjcxNGExMS40MjksMTEuNDI5LDAsMCwxLDAtMjIuODU3aDM0LjI4NmExMS40MjksMTEuNDI5LDAsMCwxLDAsMjIuODU3Wm0wLTQ1LjcxNGExMS40MjksMTEuNDI5LDAsMCwxLDAtMjIuODU3aDU3LjE0M2ExMS40MjksMTEuNDI5LDAsMSwxLDAsMjIuODU3Wm0wLTQ1LjcxNGExMS40MjksMTEuNDI5LDAsMSwxLDAtMjIuODU3aDgwYTExLjQyOSwxMS40MjksMCwxLDEsMCwyMi44NTdaIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSg5NDEuMDA0IDI3MSkiIGZpbGw9IiM3NzciLz48L2c+PC9zdmc+";
    let zindexBackIconImg = document.createElement('img');
    zindexBackIconImg.src = zindexBackIcon;

    fabric.Object.prototype.controls.zindexBackControl = new fabric.Control({
      x: 0.5,
      y: -0.5,
      offsetX: 0,
      offsetY: -65,
      cursorStyle: 'pointer',
      mouseUpHandler: handleClickSetActiveObjectZindex,
      render: renderZindexBackIcon,
      cornerSize: 24,
      touchCornerSize: 24
    });

    function renderZindexBackIcon(ctx, left, top, styleOverride, fabricObject) {
      renderCustomIcon.call(this, ctx, left, top, styleOverride, fabricObject, zindexBackIconImg);
    }

    let longTapTimer;
    function touchStartEvent(e) {
      lastPosX.value = e.touches[0].clientX;
      lastPosY.value = e.touches[0].clientY;
      lastScale.value = canvas.value.getZoom();

      // 長押しの判定
      isLongPress.value = false;
      if (e.touches.length == 1) {
        longTapTimer = setTimeout(() => {
          isLongPress.value = true;
          currentStatus.value = 'pan';
          canvas.value.selection = false;
        }, 200);
      }
    }

    function touchMoveEvent(e) {
      var vpt = canvas.value.viewportTransform;
      if (isLongPress.value) {
        canvas.value.selection = false;
        const pointer = getPointer(e.touches[0]);

        // 位置操作
        vpt[4] += pointer.x - lastPosX.value;
        vpt[5] += pointer.y - lastPosY.value;
        canvas.value.requestRenderAll();
        lastPosX.value = pointer.x;
        lastPosY.value = pointer.y;

      } else if (e.touches && e.touches.length >= 2) {
        canvas.value.selection = false;
        const pointer = getPointer(e.touches[0]);

        // 位置操作
        vpt[4] += pointer.x - lastPosX.value;
        vpt[5] += pointer.y - lastPosY.value;
        canvas.value.requestRenderAll();
        lastPosX.value = pointer.x;
        lastPosY.value = pointer.y;

        if (!isLongPress.value) {
          if(lastDistance.value == 0) lastDistance.value = getTouchDistance(e);

          // ズーム操作
          var touch1 = e.touches[0];
          var touch2 = e.touches[1];
          var distance = Math.sqrt(
            (touch1.clientX - touch2.clientX) * (touch1.clientX - touch2.clientX) +
            (touch1.clientY - touch2.clientY) * (touch1.clientY - touch2.clientY)
          );

          var scaleMultiplier = lastScale.value + (distance - lastDistance.value) / 400; // ズームの感度調整
          // scaleMultiplierの最小は.3
          if(scaleMultiplier < .3) scaleMultiplier = .3;
          canvas.value.zoomToPoint(new fabric.Point(pointer.x, pointer.y), scaleMultiplier);
        }
        clearTimeout(longTapTimer);
      } else {
        clearTimeout(longTapTimer);
      }

      if (e.touches.length == 2) {
        e.preventDefault();
      }
    }

    function touchEndEvent() {
      lastDistance.value = 0;
      lastScale.value = canvas.value.getZoom();
      canvas.value.selection = true;

      if (isLongPress.value) {
        isLongPress.value = false;
        select();
      } else {
        clearTimeout(longTapTimer)
      }
    }
    // レンダリング完了後にイベントリスナーを登録
    window.addEventListener('touchstart', touchStartEvent);
    window.addEventListener('touchmove', touchMoveEvent);
    window.addEventListener('touchend', touchEndEvent);

    canvas.value.on('mouse:down', (opt) => {
      if (isAddStringFlag.value) {
        addText(opt);
      }
      if (currentStatus.value == 'select') {
        // if (process.env.VUE_APP_LOGGING === 'true') console.log(opt)
      }
      if (currentStatus.value == 'templateString') {
        addString(currentAddObject.value, opt);
        selectSettingReset();
      }
      if (currentStatus.value == 'rectangle') {
        addRectangle(currentAddObject.value, opt);
        currentStatus.value = prevStatus.value;
        selectSettingReset();
      }
      if (currentStatus.value == 'mosaic') {
        createMosaic(false, opt)
        selectSettingReset();
      }
      if (currentStatus.value == 'pan') {
        var pointer = getPointer(opt.e);
        isDragging.value = true;
        lastPosX.value = pointer.x;
        lastPosY.value = pointer.y;
      }
      if (currentStatus.value == 'svg') {
        addSVG(opt);
      }
    });

    canvas.value.on('mouse:move', (opt) => {
      if (isDragging.value && currentStatus.value == 'pan') {
        var pointer = getPointer(opt.e);
        var vpt = canvas.value.viewportTransform;
        vpt[4] += pointer.x - lastPosX.value;
        vpt[5] += pointer.y - lastPosY.value;
        canvas.value.requestRenderAll();
        lastPosX.value = pointer.x;
        lastPosY.value = pointer.y;
      }
    });

    canvas.value.on('mouse:up', (opt) => {
      if (isDragging.value && currentStatus.value == 'pan') {
        let zoom = canvas.value.getZoom();
        const center = canvas.value.getCenter();
        canvas.value.zoomToPoint(new fabric.Point(center.left, center.top), zoom);

        opt.e.preventDefault();
        opt.e.stopPropagation();
      }
      lastDistance.value = 0;
      // ドラッグ終了
      isDragging.value = false;
    });

    canvas.value.on('object:moving', (e) => {
      let obj = e.target;

      // 以下のコードはオブジェクトがキャンバスの境界を越えないように制限します
      obj.setCoords();
    });

    canvas.value.on('mouse:wheel', (opt) => {
      const delta = opt.e.deltaY;
      let zoom = canvas.value.getZoom();
      zoom *= 0.999 ** delta;
      if (zoom > 10) zoom = 10;
      if (zoom < 0.3) zoom = 0.3;
      // const center = canvas.value.getCenter();
      // canvas.value.zoomToPoint(new fabric.Point(center.left, center.top), zoom);
      const pointer = getPointer(opt.e);
      canvas.value.zoomToPoint(new fabric.Point(pointer.x, pointer.y), zoom);
      opt.e.preventDefault();
      opt.e.stopPropagation();
    });

    // オブジェクトが選択されたときのイベントリスナーを追加
    canvas.value.on('selection:created', (e) => {
      if (process.env.VUE_APP_LOGGING === 'true') console.log(e);
      let clipArea = e.target._objects ? e.target._objects.find((obj) => {
        return obj.label === 'clipArea';
      }) : false;
      if (e.target.label === 'clipArea' || clipArea) {
        canvas.value.clipPath = null;
      }
    });

    // 既存の選択が更新されたとき（別のオブジェクトが選択されたとき）も同様のチェックを行う
    canvas.value.on('selection:updated', (e) => {
      if (process.env.VUE_APP_LOGGING === 'true') console.log(e);
      let clipArea = e.target._objects ? e.target._objects.find((obj) => {
        return obj.label === 'clipArea';
      }) : false;
      if (e.target.label === 'clipArea' || clipArea) {
        canvas.value.clipPath = null;
      }
    });
    canvas.value.on('selection:cleared', () => {
      let clipArea = canvas.value.getObjects().find((obj) => {
        return obj.label === 'clipArea';
      });
      if (clipArea) {
        applyClipToBackground();
      }
    });

    canvas.value.on('object:added', updateHistory);
    canvas.value.on('object:modified', (e) => {
      if (e.target.customId === 'mosaic') {
        applyMosaic(e.target);
      }
      updateHistory();
    });

    document.addEventListener('mousemove', (event) => {
      const tempDummyIcon = document.querySelector('.tempDummyIcon');
      tempDummyIcon.style.left = event.pageX + 'px';
      tempDummyIcon.style.top = event.pageY + 'px';
    });

  });

  const selectSettingReset = () => {
    currentStatus.value = 'select';
    currentAddObject.value = '';
    select();
  }

  const getPointer = (e) => {
    if (e.touches && e.touches[0]) {
      return { x: e.touches[0].clientX, y: e.touches[0].clientY };
    }
    return { x: e.clientX, y: e.clientY };
  };

  function updateHistory() {
    if (isUndoRedoOperation) return;
    // 現在の履歴インデックスより後の履歴を削除
    if (historyIndex.value < history.length - 1) {
      history.splice(historyIndex.value + 1);
    }

    // オリジナルのtoObjectメソッドを保存
    fabric.Object.prototype.originalToObject = fabric.Object.prototype.toObject;

    // toObjectメソッドをオーバーライド
    fabric.Object.prototype.toObject = (function (toObject) {
      return function(propertiesToInclude) {
        propertiesToInclude = (propertiesToInclude || []).concat(['customId', 'label']);
        return toObject.apply(this, [propertiesToInclude]);
      };
    })(fabric.Object.prototype.toObject);

    // キャンバスの現在の状態を履歴に追加
    history.push(JSON.stringify(canvas.value));
    historyIndex.value++;
  }

  function undo() {
    historyIndex.value--;
    isUndoRedoOperation = true;

    // historyIndexが0だった場合は背景画像以外のオブジェクトを全て削除
    if (historyIndex.value == 0) {
      canvas.value.getObjects().forEach((obj) => {
        if (obj.label !== 'background') {
          canvas.value.remove(obj);
        }
      });
      historyIndex.value = 0;
      isUndoRedoOperation = false;
      return;
    } else {
      canvas.value.loadFromJSON(history[historyIndex.value], () => {
        canvas.value.renderAll();
        canvas.value.getObjects().forEach((obj) => {
          if (obj.customId == 'mosaic') {
            createMosaic(obj);
            canvas.value.remove(obj);
          }
        });
        isUndoRedoOperation = false;
      });
    }
  }
  function redo() {
    historyIndex.value++;
    isUndoRedoOperation = true;
    canvas.value.loadFromJSON(history[historyIndex.value], () => {
      canvas.value.renderAll();
      canvas.value.getObjects().forEach((obj) => {
        if (obj.customId == 'mosaic') {
          createMosaic(obj);
          canvas.value.remove(obj);
        }
      });
      isUndoRedoOperation = false;
    });
  }

  //
  // 画面の移動（パンモード）
  //
  function togglePanMode() {
    if (currentStatus.value == 'pan') {
      select();
    } else {
      currentStatus.value = 'pan';
      canvas.value.selection = false;
    }
  }

  function addShapeOnMouseDown(event) {
    isEditing = true;
    isUndoRedoOperation = true;
    if(canvas.value.getActiveObject()) return;
    mouseDown = true;


    canvas.value.getObjects().forEach((obj) => {
      obj.selectable = false;
    });

    let pointer = canvas.value.getPointer(event.e);
    linePath = `M ${pointer.x} ${pointer.y} L ${pointer.x} ${pointer.y}`;
    line = new fabric.Path(linePath, {
      stroke: textColor.value,
      strokeWidth: 5,
      fill: 'transparent',
      originX: 'center',
      originY: 'center',
      hasCntrols: false,
      hasBorders: false,
      objectCaching: false,
      selectable: false,
    });

    canvas.value.add(line);

    let arrowheadPath = `M -10 0 L 10 10 L -10 20 z`;
    arrowHead = new fabric.Path(arrowheadPath, {
      label: 'arrowLine',
      customId: 'arrowHead',
      fill: textColor.value,
      stroke: textColor.value,
      strokeWidth: 0,
      originX: 'center',
      originY: 'center',
      hasCntrols: false,
      hasBorders: false,
      objectCaching: false,
      selectable: false,
      top: pointer.y,
      left: pointer.x,
    });
    canvas.value.add(arrowHead);

    mouseDownPoint = pointer;

    canvas.value.requestRenderAll();
  }

  function addShapeOnMouseMove(event) {
    if(!mouseDown) return;
    let pointer = canvas.value.getPointer(event.e);
    line.path[1][1] = pointer.x;
    line.path[1][2] = pointer.y;
    line.setCoords();

    arrowHead.left = pointer.x;
    arrowHead.top = pointer.y;
    let width = Math.abs(pointer.x - line.path[0][1]);
    let height = Math.abs(pointer.y - line.path[0][2]);
    let rotate = height / width;
    let angle = Math.atan(rotate) / Math.PI * 180;

    if(arrowHead.left >= mouseDownPoint.x) {
      if(arrowHead.top <= mouseDownPoint.y) {
        arrowHead.angle = 360 - angle;
      } else {
        arrowHead.angle = angle;
      }
    } else {
      if(arrowHead.top <= mouseDownPoint.y) {
        arrowHead.angle = 180 + angle;
      } else {
        arrowHead.angle = 180 - angle;
      }
    }

    arrowHead.setCoords();
    canvas.value.requestRenderAll();
  }

  function addShapeOnMouseUp() {
    if(!mouseDown) return;

    mouseDown = false;
    let updatedLinePath = line.path;
    canvas.value.remove(line);
    line = new fabric.Path(updatedLinePath, {
      id: 'addLine',
      customId: 'arrowLine',
      label: 'arrowLine',
      stroke: textColor.value,
      strokeWidth: 5,
      fill: 'transparent',
      originX: 'center',
      originY: 'center',
      // hasCntrols: false,
      // hasBorders: false,
      objectCaching: false,
      selectable: false,
    });
    canvas.value.add(line);

    let objects = [];
    canvas.value.getObjects().forEach((obj) => {
      if(obj.label === 'arrowLine') {
        objects.push(obj);
      }
    });
    let singleArrow = new fabric.Group(objects, {
      id: 'singleArrow',
      originX: 'center',
      originY: 'center',
      hasCntrols: false,
      hasBorders: false,
      objectCaching: false,
    });
    canvas.value.remove(line, arrowHead);
    isUndoRedoOperation = false;
    canvas.value.add(singleArrow);

    singleArrow.on('mouseover', () => { enableSelectionMode() })
    singleArrow.on('mouseout', () => { enableDrawingMode() })
    singleArrow.on('scaling', () => { arrowScaling(singleArrow) })
    canvas.value.requestRenderAll();
    isEditing = false;
    select();
  }

  function arrowScaling(singleArrow) {
    let minScale = 0.8;
    let maxScale = 2;

    let arrowHead = singleArrow._objects.filter(item => item.customId === 'arrowHead');
    let arrowLine = singleArrow._objects.filter(item => item.customId === 'arrowLine');

    arrowLine[0].set({
      strokeUniform: false,
      strokeWidth: 5,
    });
    arrowHead[0].scaleX = 1;
    arrowHead[0].scaleY = 1;

    if(singleArrow.scaleX < minScale || singleArrow.scaleY < minScale) {
      arrowLine[0].set({
        strokeWidth: 5 * minScale,
        strokeUniform: true
      });
    }
    if(singleArrow.scaleX < minScale) {
      arrowHead[0].scaleX = minScale / singleArrow.scaleX;
    }
    if(singleArrow.scaleY < minScale) {
      arrowHead[0].scaleY = minScale / singleArrow.scaleY;
    }

    if (singleArrow.scaleX > maxScale || singleArrow.scaleY > maxScale) {
      arrowLine[0].set({
        strokeWidth: 5 * maxScale,
        strokeUniform: true
      });
    }
    if (singleArrow.scaleX > maxScale) {
      arrowHead[0].scaleX = maxScale / singleArrow.scaleX;
    }
    if (singleArrow.scaleY > maxScale) {
      arrowHead[0].scaleY = maxScale / singleArrow.scaleY;
    }
  }

  // ========================
  // 画像アップロード関連
  // ========================
  const toBase64Url = binary =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(binary);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });

  async function buildNewAttachments(files) {
    isLoading.value = true;
    const file = files[0];

    // 画像サイズが10MBを超える場合はエラー
    const SIZE10MOVER = file.size / 1024 ** 2 > 10;
    // jpeg or pngでなければエラー
    const JPEGorPNG = file.type !== "image/jpeg" && file.type !== "image/png";
    setting.value.imageValideteErrorMsg = '';
    if (SIZE10MOVER || JPEGorPNG) {
      if (SIZE10MOVER && JPEGorPNG) {
        setting.value.imageValideteErrorMsg = "JPEGもしくはPNG形式の10MB以下の画像を登録してください。";
        isLoading.value = false;
        return;
      } else if (SIZE10MOVER) {
        setting.value.imageValideteErrorMsg = "10MB以下の画像を登録してください。";
        isLoading.value = false;
        return;
      } else {
        setting.value.imageValideteErrorMsg = "JPEGもしくはPNG形式の画像を登録してください。";
        isLoading.value = false;
        return;
      }
    }

    handleImageCompression(file);
  }

  async function handleImageCompression(file, loadflag) {
    try {
      const options = {
        maxSizeMB: 2,
        maxWidthOrHeight: 1024
      }

      const compressedFile = await imageCompression(file, options);
      const img = URL.createObjectURL(compressedFile);
      if (process.env.VUE_APP_LOGGING === 'true') console.log(img, compressedFile);

      const base64Url = await toBase64Url(compressedFile);
      setting.value.imageurl = null;
      setting.value.base64Url = base64Url;
      isLoading.value = false;

      fabric.Image.fromURL(setting.value.base64Url, img => {
        let winW = window.innerWidth;
        let calkW = winW >= 1600 ? 1600 : winW;
        let scaleFactor = img.width >= 1600 ? calkW / img.width : 1;
        if (process.env.VUE_APP_LOGGING === 'true') console.log(scaleFactor);
        img.scale(scaleFactor);
        setting.value.imageScale = scaleFactor;

        img.set({
          left: (canvas.value.width / 2) - (img.width / 2),
          top: (canvas.value.height / 2) - (img.height / 2),
        });

        if (!loadflag) {
          canvas.value.setBackgroundImage(img);
        }
        // img.set({
        //   customId: 'backgroundImage',
        //   selectable: false
        // });
        setting.value.image = img;
        setting.value.imageWidth = img.width * scaleFactor;
        setting.value.imageHeight = img.height * scaleFactor;
        // canvas.value.add(img);
        // canvas.value.sendToBack(img);
        // canvas.value.setWidth(setting.value.imageWidth);
        // canvas.value.setHeight(setting.value.imageHeight);
        canvas.value.renderAll();
        updateHistory();
      })



    } catch (error) {
      if (process.env.VUE_APP_LOGGING === 'true') console.log(error.message);
      isLoading.value = false;
    }
  }

  function onDropOntoUploader(event) {
    buildNewAttachments(event.dataTransfer.files);
    isImageSetModalOpen.value = setting.value.imageValideteErrorMsg ? true : false;
  }

  async function onChangeFileUpload(event) {
    await buildNewAttachments(event.target.files);
    event.target.value = null; // fileフィールドをリセット
    isImageSetModalOpen.value = setting.value.imageValideteErrorMsg ? true : false;
  }

  // =======================
  // モザイク処理
  // =======================
  function createMosaic(initItem, options) {
    const pointer = options ? canvas.value.getPointer(options.e) : false;
    // モザイク処理を適用する領域を矩形で選択します
    // let scale = canvas.value.getZoom();
    // if (process.env.VUE_APP_LOGGING === 'true') console.log(scale, setting.value.imageScale);
    const mosaicRect = new fabric.Rect({
        left: pointer ? pointer.x : initItem.left,
        top: pointer ? pointer.y : initItem.top,
        width: initItem ? initItem.width : 200,
        height: initItem ? initItem.height : 200,
        scaleX: initItem ? initItem.scaleX : 1,
        scaleY: initItem ? initItem.scaleY : 1,
        fill: 'transparent',
        customId: 'mosaic',
        stroke: initItem ? initItem.stroke : textColor.value,
        strokeWidth: 2,
        strokeUniform: true,
        lockRotation: true,
        hasRotatingPoint: false,
        lockScalingFlip: true,
    });
    canvas.value.add(mosaicRect);

    applyMosaic(mosaicRect);

    // mosaicRect.on('modified', () => { applyMosaic(mosaicRect) });
    mosaicRect.on('mouseover', () => { enableSelectionMode() })
    mosaicRect.on('mouseout', () => { enableDrawingMode() })

  }

  // モザイク処理を実行する関数
  function applyMosaic(rect) {
    rect.fill = '';

    const currentViewportTransform = canvas.value.viewportTransform;
    canvas.value.viewportTransform = [1, 0, 0, 1, 0, 0 ];

    const center = canvas.value.getCenter();
    canvas.value.zoomToPoint(new fabric.Point(center.left, center.top), 1);

    const bounds = getRotatedBoundingRect(canvas.value.backgroundImage);
    rect.width = rect.width > bounds.width ? bounds.width : rect.width;
    rect.height = rect.height > bounds.height ? bounds.height : rect.height;

    const tempCanvas = document.createElement('canvas');
    const tempCtx = tempCanvas.getContext('2d');
    const pixelSizeX = 10 / rect.scaleX;  // モザイクのピクセルサイズ
    const pixelSizeY = 10 / rect.scaleY;  // モザイクのピクセルサイズ

    tempCanvas.width = rect.width;
    tempCanvas.height = rect.height;

    // キャンバスからすべてのオブジェクトを取得（背景画像を除く）
    const allObjects = canvas.value.getObjects();

    // 背景画像以外のオブジェクトを一時的に削除
    allObjects.forEach(obj => {
      if (obj !== canvas.value.backgroundImage) {
        canvas.value.remove(obj);
      }
    });

    const base64 = canvas.value.toDataURL({
      left: bounds.left,
      top: bounds.top,
      width: bounds.width,
      height: bounds.height
    });

    canvas.value.viewportTransform = currentViewportTransform;

    // オブジェクトを元に戻す
    allObjects.forEach(obj => {
      if (obj !== canvas.value.backgroundImage) {
        canvas.value.add(obj);
      }
    });

    // 画像オブジェクトを作成
    const image = new Image();

    // 画像が読み込まれたらキャンバスに描画
    image.onload = () => {

      // ビューポート変換（ズームおよびパン）を取得

      tempCtx.drawImage(
        image,
        rect.left - bounds.left,
        rect.top - bounds.top,
        rect.width * rect.scaleX,
        rect.height * rect.scaleY,
        0,
        0,
        rect.width,
        rect.height
      );

      for(let y = 1; y < rect.height; y += pixelSizeY) {
        for(let x = 1; x < rect.width; x += pixelSizeX) {
          const color = tempCtx.getImageData(x + 5, y + 5, 1, 1).data;
          tempCtx.fillStyle = `rgba(${color[0]}, ${color[1]}, ${color[2]}, ${color[3] / 255})`;
          tempCtx.fillRect(x, y, pixelSizeX, pixelSizeY);
        }
      }

      rect.set('fill', new fabric.Pattern({
        source: tempCanvas,
        repeat: 'no-repeat'
      }));

      canvas.value.renderAll();
    };

    // 画像のソースをBase64データに設定
    image.src = base64;
  }

  // ========================
  // テキスト追加
  // ========================
  function addText(options) {
    const pointer = canvas.value.getPointer(options.e);
    var fontSize = window.innerWidth <= 768 ? 35 : 20;

    // テキストインスタンスの作成
    const text = new fabric.IText('テキストを入力', {
      left: pointer.x,
      top: pointer.y,
      fontFamily: 'Shin Go Regular',
      fontSize: fontSize,
      // fontFamily: "Noto Sans JP",
      fill: textColor.value,
      shadow: '#fff 0px 0px 2px'
    });

    canvas.value.add(text);

    // テキストを編集モードにする
    text.enterEditing();
    // キャレットをテキストの末尾に配置する
    text.selectAll();
    // キャンバスを再描画して変更を反映する
    canvas.value.renderAll();

    isAddStringFlag.value = false;
    text.on('mouseover', () => { enableSelectionMode() })
    text.on('mouseout', () => { enableDrawingMode() })

    select();
  }

  // ========================
  // 文字列追加
  // ========================
  function addString(type, options) {
    const pointer = canvas.value.getPointer(options.e);
    // 文字列を1文字ごとに分割
    var string = type == 'number' ? "1,2,3,4" : "①,②,③,④";
    var chars = string.split(',');
    var fontSize = window.innerWidth <= 768 ? 35 : 20;

    // 最初の文字の位置
    var leftPosition = 0;

    chars.forEach(function(char) {
      // fabric.ITextを作成
      var text = new fabric.IText(char, {
        left: pointer.x + leftPosition,
        top: pointer.y,
        fontSize: fontSize,
        fontFamily: 'Shin Go Regular',
        // fontFamily: "Noto Sans JP",
        fill: textColor.value,
        textBackgroundColor: complementaryColor.value
      });

      // オブジェクトをキャンバスに追加
      canvas.value.add(text);

      // 次の文字の位置を計算
      leftPosition += text.width + 5;  // 5は文字間のスペース
    });

    // キャンバスをレンダリング
    canvas.value.renderAll();
  }

  // ========================
  // 図形追加
  // ========================
  function addRectangle(type, options) {
    const pointer = canvas.value.getPointer(options.e);
    switch(type) {
      case "square": {
        let fullRect = new fabric.Rect({
          width: 100,
          height: 100,
          fill: textColor.value, // 塗りつぶし色
          left: pointer.x,
          top: pointer.y,
        });
        canvas.value.add(fullRect);
        break;
      }
      case "squareStroke": {
        let strokeRect = new fabric.Rect({
          width: 100,
          height: 100,
          fill: '',
          stroke: textColor.value,
          strokeWidth: 5,
          left: pointer.x,
          top: pointer.y,
          strokeUniform: true
        });
        canvas.value.add(strokeRect);
        break;
      }
      case "circle": {
        let fullCircle = new fabric.Circle({
          radius: 50,
          fill: textColor.value, // 塗りつぶし色
          left: pointer.x,
          top: pointer.y,
        });
        canvas.value.add(fullCircle);
        break;
      }
      case "circleStroke": {
        let strokeCircle = new fabric.Circle({
          radius: 50,
          fill: '',
          stroke: textColor.value,
          strokeWidth: 5,
          left: pointer.x,
          top: pointer.y,
          strokeUniform: true
        });
        canvas.value.add(strokeCircle);
        break;
      }
    }
  }

  // ========================
  // アイコンの追加
  // ========================
  function handleClickAddSVG(src) {
    currentStatus.value = 'svg';
    dummyIcon.value = src;
    isSVGShowFlag.value = false;
  }

  function addSVG(options) {
    const pointer = canvas.value.getPointer(options.e);

    // dummyIcon.valueに設定されているSVG画像をpointer.x, pointer.yの位置に追加する
    fabric.loadSVGFromURL(dummyIcon.value, (objects, options) => {
      const group = fabric.util.groupSVGElements(objects, options);
      // 元のSVGのサイズを取得
      const originalWidth = group.width;
      // 目的の幅に合わせてスケールを計算
      const scaleFactor = 25 / originalWidth;

      group.set({
        left: pointer.x,
        top: pointer.y,
        customId: 'svg',
        label: 'svg',
        fill: textColor.value,
        hasRotatingPoint: false,
        scaleX: scaleFactor,
        scaleY: scaleFactor,
        originX: 'center',
        originY: 'center',
        selectable: false,
      });
      canvas.value.add(group);
      canvas.value.renderAll();

      isSVGShowFlag.value = false;
      dummyIcon.value = null;
      select();
    });
  }

  // ========================
  // オブジェクト選択
  // ========================
  function select(){
    currentStatus.value = 'select';
    canvas.value.isDrawingMode = false;
    canvas.value.selection = true;

    canvas.value.off({
      'mouse:down': addShapeOnMouseDown,
      'mouse:move': addShapeOnMouseMove,
      'mouse:up': addShapeOnMouseUp
    })

    canvas.value.getObjects().forEach((obj) => {
      if(obj.customId === 'backgroundImage' || obj.label === 'canvasWraper') {
        obj.selectable = false;
      } else {
        obj.selectable = true;
      }
    });
  }

  // ========================
  // 線描画
  // ========================
  function draw(){
    arrowOff();
    currentStatus.value = 'draw';
    canvas.value.freeDrawingBrush = new fabric.PencilBrush(canvas.value);
    canvas.value.freeDrawingBrush.width = 5;
    canvas.value.freeDrawingBrush.color = textColor.value;
    canvas.value.isDrawingMode = true;
  }

  // ========================
  // オブジェクト複製
  // ========================
  function copyObject() {
    let activeObject = canvas.value.getActiveObject();
    canvas.value.discardActiveObject();

    if (activeObject) {
      // 複数のオブジェクトが選択されている場合
      if (activeObject.type === 'activeSelection') {
        // 選択されたすべてのオブジェクトを複製
        activeObject.forEachObject(obj => {
          if(obj.label === 'clipArea') {
            return false;
          }
          if(obj.customId === 'mosaic') {
            notificationMsg.value = 'モザイク処理は複製できません。\nモザイク以外のオブジェクトを複製しました。';
            notificationClose.value = false;
            setTimeout(() => {
              notificationMsg.value = "";
              notificationClose.value = true;
            }, 3000)
            return false;
          }
          obj.clone(cloned => {
            cloned.set('left', obj.left + 30);
            cloned.set('top', obj.top + 30);
            canvas.value.add(cloned);
          });
        });

      } else if (activeObject.type === 'group') {
        // グループ化されたオブジェクトの複製
        let clones = activeObject._objects.map(obj => {
          return fabric.util.object.clone(obj);
        });

        let newGroup = new fabric.Group(clones, {
          left: activeObject.left + 10,
          top: activeObject.top + 10,
        });

        newGroup.set({
          scaleX: activeObject.scaleX,
          scaleY: activeObject.scaleY,
          id: 'singleArrow',
          originX: 'center',
          originY: 'center',
          hasCntrols: false,
          hasBorders: false,
          objectCaching: false,
        });

        canvas.value.add(newGroup);
        arrowScaling(newGroup);
        newGroup.on('scaling', () => { arrowScaling(newGroup) })

      } else {
        if(activeObject.label === 'clipArea') {
          return false;
        }
        if(activeObject.customId === 'mosaic') {
          notificationMsg.value = 'モザイク処理は複製できません。';
          notificationClose.value = false;
          setTimeout(() => {
            notificationMsg.value = "";
            notificationClose.value = true;
          }, 3000)
          return false;
        }

        // 単一のオブジェクトの複製
        activeObject.clone(cloned => {
          cloned.set({
            left: activeObject.left + 10,
            top: activeObject.top + 10,
          });
          canvas.value.add(cloned);
        });
      }
    }
    canvas.value.renderAll();
  }

  // ========================
  // オブジェクト削除
  // ========================
  function deleteObject() {
    let activeObjects = canvas.value.getActiveObject();
    canvas.value.discardActiveObject();
    if(activeObjects) {
      if(activeObjects.id === 'singleArrow') {
        canvas.value.remove(activeObjects);
      }
      if(activeObjects?._objects && activeObjects?._objects.length >= 2) {
        activeObjects._objects.forEach(function(object) {
          if (process.env.VUE_APP_LOGGING === 'true') console.log(object);
          canvas.value.remove(object);

          if(object.label === 'clipArea') {
            isClipArea.value = false;
            canvas.value.clipPath = null;
          }
        });
      } else {
        if (process.env.VUE_APP_LOGGING === 'true') console.log(activeObjects);
        canvas.value.remove(activeObjects);
      }
      if(activeObjects.label === 'clipArea') {
        isClipArea.value = false;
        canvas.value.clipPath = null;
      }
    }
  }

  // ========================
  // 画面クリップ
  // ========================
  function clip(ratio){
    isClipShowFlag.value = false;
    currentStatus.value = 'clip';

    let clipWidth = 0;
    let clipHeight = 0;
    let baseWidth = setting.value.imageWidth > setting.value.imageHeight ? setting.value.imageWidth : setting.value.imageHeight;

    if (ratio == '3:2') {
      clipWidth = baseWidth;
      clipHeight = baseWidth * 0.66666;
    } else if (ratio == '2:3') {
      clipWidth = baseWidth * 0.66666;
      clipHeight = baseWidth;
    } else if (ratio == '16:9') {
      clipWidth = baseWidth;
      clipHeight = baseWidth * 0.5625;
    } else if (ratio == '9:16') {
      clipWidth = baseWidth * 0.5625;
      clipHeight = baseWidth;
    } else {
      clipWidth = baseWidth;
      clipHeight = baseWidth;
    }

    const bounds = getRotatedBoundingRect(canvas.value.backgroundImage);

    // 背景画像のサイズ、角度に合わせて矩形を作成
    const clipArea = new fabric.Rect({
      label: 'clipArea',
      left: bounds.left + (bounds.width / 2),
      top: bounds.top + (bounds.height / 2),
      fill: "rgba(255, 255, 255, 0)",
      stroke: "#999",
      strokeWidth: 5,
      width: clipWidth,
      height: clipHeight,
      originX: 'center',
      originY: 'center',
      // rotatingPointOffset: 0,
      // cornerColor: 'red', // これは制御ポイントの色を設定します。
      // transparentCorners: false,
      perPixelTargetFind: true,
      hasRotatingPoint: false,
      lockRotation: true,
      lockUniScaling: true,
      targetFindTolerance: 50,
      selectable: true,
    });


    clipArea.setControlVisible('ml', false); // Top-Left
    clipArea.setControlVisible('mr', false); // Top-Right
    clipArea.setControlVisible('mt', false); // Bottom-Left
    clipArea.setControlVisible('mb', false); // Bottom-Right
    clipArea.setControlVisible('mtr', false); // rotate

    // rect.set({
    //   cornerSize: 30,
    //   padding: 0,
    //   cornerOffset: 0
    // });
    canvas.value.add(clipArea);
    applyClipToBackground();
    // clipArea.on('modified', () => {
    //   applyClipToBackground();
    // });

    // canvas.value.clipPath = clipArea;
    // canvas.value.renderAll();

    canvas.value.on('object:rotating', function(e) {
      if(e.target.label === 'clipArea') {
        var object = e.target;
        var angle = object.angle % 360;
        if (angle < 0) angle += 360;
        var snappedAngle = Math.round(angle / 45) * 45;
  
        if (snappedAngle !== angle) {
          object.angle = snappedAngle;
          object.setCoords(); // ここでsetCoordsを呼び出します。
        }
      }
    });
    isClipArea.value = true;

    // canvas.value.clipPath = rect;

    select();
  }
  function applyClipToBackground() {
    // クリップパスを設定
    let clipArea = canvas.value.getObjects().find((obj) => {
      return obj.label === 'clipArea';
    });

    canvas.value.clipPath = new fabric.Rect({
      left: clipArea.left,
      top: clipArea.top,
      width: clipArea.width * clipArea.scaleX,
      height: clipArea.height * clipArea.scaleY,
      originX: 'center',
      originY: 'center'
    });

    // キャンバスを再描画
    canvas.value.renderAll();
  }

  // ========================
  // 選択オブジェクトの配色変更
  // ========================
  function handleClickSetActiveObjectColor(color) {
    textColor.value = color;
    if(currentStatus.value == 'draw') {
      draw();
    }
    let activeObjects = canvas.value.getActiveObject();
    if(activeObjects) {
      if (process.env.VUE_APP_LOGGING === 'true') console.log(activeObjects);
      if(activeObjects?._objects && activeObjects?._objects.length >= 2) {
        activeObjects._objects.forEach(function(object) {
          if(object?._objects && object?._objects.length >= 2) {
            object._objects.forEach(function(innerObject) {
              if(innerObject.label === 'clipArea') {
                return false;
              } else if(innerObject.customId === 'svg') {
                innerObject.set('fill', textColor.value);
              } else if(innerObject.customId === 'mosaic') {
                innerObject.set('stroke', textColor.value);
              } else {
                if(innerObject.fill) innerObject.set('fill', textColor.value)
                if(innerObject.stroke) innerObject.set('stroke', textColor.value)
                if(innerObject.textBackgroundColor) innerObject.set('textBackgroundColor', complementaryColor.value)
              }
            });
          } else {
            if(object.label === 'clipArea') {
              return false;
            } else if(object.customId === 'svg') {
              object.set('fill', textColor.value);
            } else if(object.customId === 'mosaic') {
              object.set('stroke', textColor.value);
            } else {
              if(object.fill) object.set('fill', textColor.value)
              if(object.stroke) object.set('stroke', textColor.value)
              if(object.textBackgroundColor) object.set('textBackgroundColor', complementaryColor.value)
            }
          }
        });
      } else {
        if(activeObjects.label === 'clipArea') {
          return false;
        } else if(activeObjects.customId === 'svg') {
          activeObjects.set('fill', textColor.value);
        } else if(activeObjects.customId === 'mosaic') {
          activeObjects.set('stroke', textColor.value);
        } else {
          if(activeObjects.fill) activeObjects.set('fill', textColor.value);
          if(activeObjects.stroke) activeObjects.set('stroke', textColor.value);
          if(activeObjects.textBackgroundColor) activeObjects.set('textBackgroundColor', complementaryColor.value);
        }
      }
    }
    canvas.value.renderAll();
  }

  // ========================
  // 選択オブジェクトのzindex変更
  // ========================
  function handleClickSetActiveObjectZindex(type) {
    setTimeout(() => {
      let activeObjects = canvas.value.getActiveObject();
      if(activeObjects) {
        if(activeObjects?._objects && activeObjects?._objects.length >= 2) {
          activeObjects._objects.forEach(function(object) {
            if(object?._objects && object?._objects.length >= 2) {
              object._objects.forEach(function(innerObject) {
                if(type === 'front') {
                  canvas.value.bringToFront(innerObject);
                } else {
                  canvas.value.sendToBack(innerObject);
                }
              });
            } else {
              if(type === 'front') {
                canvas.value.bringToFront(object);
              } else {
                canvas.value.sendToBack(object);
              }
            }
          });
        } else {
          if(type === 'front') {
            canvas.value.bringToFront(activeObjects);
          } else {
            canvas.value.sendToBack(activeObjects);
          }
        }
      }
      canvas.value.renderAll();
    },10)
  }
  function handleClickSetActiveObjectZindexFront() {
    setTimeout(() => {
      let activeObjects = canvas.value.getActiveObject();
      if(activeObjects) {
        if(activeObjects?._objects && activeObjects?._objects.length >= 2) {
          activeObjects._objects.forEach(function(object) {
            if(object?._objects && object?._objects.length >= 2) {
              object._objects.forEach(function(innerObject) {
                canvas.value.bringToFront(innerObject);
              });
            } else {
              canvas.value.bringToFront(object);
            }
          });
        } else {
          canvas.value.bringToFront(activeObjects);
        }
      }
      canvas.value.renderAll();
    },10)
  }

  // ========================
  // 黒板の描写
  // ========================
  const isBlackboardPrint = ref(false);
  function saveBlackboard() {
    isBlackboardPrint.value = true;
    // 強制的に1秒待つ
    setTimeout(() => {
      let domtoimageScale = 2;
      const PNG = document.querySelector("#blackboard");
      domtoimage.toPng(PNG, {
        quality: 0.95,
        width: PNG.clientWidth * domtoimageScale,
        height: PNG.clientHeight * domtoimageScale,
        style: {
          transform: 'scale('+domtoimageScale+')',
          transformOrigin: 'top left'
        }
      })
      .then(function (dataUrl) {
        var img = dataUrl;

        fabric.Image.fromURL(img, function(oImg) {
          if (process.env.VUE_APP_LOGGING === 'true') console.log(oImg);
          // 背景画像の1/4サイズを算出、画像のサイズがそれより大きい場合は1/4サイズにする
          const bounds = getRotatedBoundingRect(canvas.value.backgroundImage);
          let scale = oImg.width > bounds.width / 4 ? bounds.width / 4 / oImg.width : 1;
          oImg.scale(scale);

          // 画像はキャンバスの右下20pxの位置に配置する、縮小されている場合はその分の位置を調整する
          oImg.set({
            left: bounds.left + bounds.width - (oImg.width * scale) - 20,
            top: bounds.top + bounds.height - (oImg.height * scale) - 20,
          });
          canvas.value.add(oImg);

          isBlackbordModalOpen.value = false;
          isBlackboardPrint.value = false;
          canvas.value.requestRenderAll();

        });
      })
      .catch(function (error) {
        isBlackbordModalOpen.value = false;
        isBlackboardPrint.value = false;
        if (process.env.VUE_APP_LOGGING === 'true') console.error('oops, something went wrong!', error);
      });
    }, 100);

    // // 強制的に1秒待つ
    // setTimeout(() => {
    //   html2canvas(document.querySelector("#blackboard")).then(blackboard => {
    //     var img = blackboard.toDataURL("image/png");

    //     fabric.Image.fromURL(img, function(oImg2) {
    //       if (process.env.VUE_APP_LOGGING === 'true') console.log(oImg2);
    //       // 背景画像の1/4サイズを算出、画像のサイズがそれより大きい場合は1/4サイズにする
    //       const bounds = getRotatedBoundingRect(canvas.value.backgroundImage);
    //       let scale = oImg2.width > bounds.width / 4 ? bounds.width / 4 / oImg2.width : 1;
    //       oImg2.scale(scale);

    //       // 画像はキャンバスの右下20pxの位置に配置する、縮小されている場合はその分の位置を調整する
    //       oImg2.set({
    //         left: bounds.left + bounds.width - (oImg2.width * scale) - 20,
    //         top: bounds.top + bounds.height - (oImg2.height * scale) - 20,
    //       });
    //       canvas.value.add(oImg2);

    //       // isBlackbordModalOpen.value = false;
    //       // isBlackboardPrint.value = false;

    //     });
    //   });
    // }, 100);
  }

  function setBlackboardBody() {
    blackboardBody.value = blackboardRentRepairDetail.value;
  }

  // ========================
  // ダウンロード（アップロード）
  // ========================
  function createDownloadImage() {
    // canvas.valueのオブジェクト内にlabel === 'clipArea'のものが存在するかを確認
    let clipArea = canvas.value.getObjects().find((obj) => {
      return obj.label === 'clipArea';
    });

    if (clipArea) {
      const bounds = getRotatedBoundingRect(clipArea);

      // clipAreaの中心を基準にキャンバスを等倍にする
      canvas.value.viewportTransform = [1, 0, 0, 1, 0, 0 ];
      canvas.value.renderAll();

      clipArea.strokeWidth = 0;
      // オブジェクトの位置とサイズを基にキャンバスから切り抜く
      const base64 = canvas.value.toDataURL({
        left: bounds.left - bounds.width / 2,
        top: bounds.top - bounds.height / 2,
        width: bounds.width,
        height: bounds.height,
        format: "png"
      });
      clipArea.strokeWidth = 10;

      return base64;

    } else {
      const bounds = getRotatedBoundingRect(canvas.value.backgroundImage);

      // canvas.value.backgroundImageの中心を基準にキャンバスを等倍にする
      canvas.value.viewportTransform = [1, 0, 0, 1, 0, 0 ];
      canvas.value.renderAll();

      //
      const base64 = canvas.value.toDataURL({
        left: bounds.left,
        top: bounds.top,
        width: bounds.width,
        height: bounds.height,
        format: "png"
      });

      return base64;
    }
  }
  function download(){

    const link = document.createElement("a");

    let currentViewportTransform = canvas.value.viewportTransform;

    link.href = createDownloadImage();
    link.download = "sample.png";
    link.click();

    canvas.value.viewportTransform = currentViewportTransform;
    canvas.value.renderAll();

    // link.href = setting.value.base64Url;
    // link.download = "sample_base.png";
    // link.click();

    // let DataJSON = JSON.stringify(canvas.value);
    // const blob = new Blob([DataJSON], { type: "application/json" });
    // const url = URL.createObjectURL(blob);
    // link.href = url;
    // link.download = "base.log";
    // link.click();
  }
  function getRotatedBoundingRect(image) {
    var angle = image.angle * Math.PI / 180;  // ラジアンに変換
    var width = image.width * image.scaleX;
    var height = image.height * image.scaleY;

    // 回転行列を使用して各頂点の新しい位置を計算
    var cosTheta = Math.cos(angle);
    var sinTheta = Math.sin(angle);
    var tl = { x: 0, y: 0 };  // 左上
    var tr = { x: width, y: 0 };  // 右上
    var bl = { x: 0, y: height };  // 左下
    var br = { x: width, y: height };  // 右下
    var vertices = [tl, tr, bl, br].map(function(point) {
      return {
        x: image.left + (point.x * cosTheta - point.y * sinTheta),
        y: image.top + (point.x * sinTheta + point.y * cosTheta)
      };
    });

    // 境界矩形を計算
    var minX = Math.min.apply(null, vertices.map(function(v) { return v.x; }));
    var minY = Math.min.apply(null, vertices.map(function(v) { return v.y; }));
    var maxX = Math.max.apply(null, vertices.map(function(v) { return v.x; }));
    var maxY = Math.max.apply(null, vertices.map(function(v) { return v.y; }));

    return {
      left: minX,
      top: minY,
      width: maxX - minX,
      height: maxY - minY
    };
  }

  // ========================
  // 矢印描画
  // ========================
  function arrow() {
    canvas.value.isDrawingMode = false;
    if(currentStatus.value === 'arrow') return;
    currentStatus.value = 'arrow';
    canvas.value.selection = false;
    canvas.value.getObjects().forEach((obj) => {
      obj.selectable = false;
    });
    canvas.value.on({
      'mouse:down': addShapeOnMouseDown,
      'mouse:move': addShapeOnMouseMove,
      'mouse:up': addShapeOnMouseUp
    })
  }
  function arrowOff() {
    canvas.value.off({
      'mouse:down': addShapeOnMouseDown,
      'mouse:move': addShapeOnMouseMove,
      'mouse:up': addShapeOnMouseUp
    })
  }

  // ========================
  // 画面のズームイン・ズームアウト
  // ========================
  function handleClickZoom(type) {
    let zoom = canvas.value.getZoom();
    if(type) {
      zoom = zoom * 1.2;
      if (zoom > 10) zoom = 10;
    } else {
      zoom = zoom * 0.8;
      if (zoom < 0.3) zoom = 0.3;
    }
    const center = canvas.value.getCenter();
    canvas.value.zoomToPoint(new fabric.Point(center.left, center.top), zoom);
  }

  // 描画モードの設定
  function enableDrawingMode() {
    if (currentStatus.value == 'svg' || currentStatus.value == 'templateString' || currentStatus.value == 'rectangle') return;
    if (prevStatus.value == 'draw') {
      draw();
    } else if (prevStatus.value == 'select') {
      select();
    } else if (prevStatus.value == 'arrow') {
      arrow();
    } else if (prevStatus.value == 'text') {
      isAddStringFlag.value = true;
    } else {
      select();
    }
  }

  // 選択モードの設定
  function enableSelectionMode() {
    if (isEditing || currentStatus.value == 'svg' || currentStatus.value == 'templateString' || currentStatus.value == 'rectangle') return;
    isAddStringFlag.value = false;
    prevStatus.value = currentStatus.value;
    select();
  }

  // キーの押下イベントを監視
  document.addEventListener('keydown', function(e) {
    // Delete キー (または Backspace キー) が押された場合
    if (e.keyCode === 46 || e.keyCode === 8) {
      // 選択中のオブジェクトを取得
      let activeObjects = canvas.value.getActiveObjects();
      // 選択されているオブジェクトがテキストオブジェクトでかつ編集中の場合は削除しない
      if (process.env.VUE_APP_LOGGING === 'true') console.log(activeObjects);
      if (activeObjects.some(function(object) { return object.type === 'i-text' && object.isEditing })) {
        return;
      }

      deleteObject();

      // 選択を解除
      canvas.value.discardActiveObject().renderAll();
    }
    // Space キーが押された場合Panモードに切り替える
    else if (e.keyCode === 32) {
      // 選択中のオブジェクトを取得
      let activeObjects = canvas.value.getActiveObjects();
      // 選択されているオブジェクトがテキストオブジェクトでかつ編集中の場合は削除しない
      if (activeObjects.some(function(object) { return object.type === 'i-text' && object.isEditing })) {
        return;
      }
      select();
      prevStatus.value = currentStatus.value;
      currentStatus.value = 'pan'
      canvas.value.selection = false;
    }
  });

  // キーアップイベントを監視
  document.addEventListener('keyup', function(e) {
    // Space キーが離された場合
    if (e.keyCode === 32) {
      // 選択中のオブジェクトを取得
      let activeObjects = canvas.value.getActiveObjects();
      // 選択されているオブジェクトがテキストオブジェクトでかつ編集中の場合は削除しない
      if (activeObjects.some(function(object) { return object.type === 'i-text' && object.isEditing })) {
        return;
      }
      select();
    }
  });

  // 補色の算出
  function getComplementary(masterCode) {
    if (masterCode == "#000000" || masterCode == "#808080") {
      return "#ffffff";
    } else if (masterCode == "#ffffff") {
      return "#000000";
    }

    // "#"を取り除く
    masterCode = masterCode.replace("#", "");

    // 2文字ずつ取得し、16進数から10進数へ変換
    let r = parseInt(masterCode.substring(0, 2), 16);
    let g = parseInt(masterCode.substring(2, 4), 16);
    let b = parseInt(masterCode.substring(4, 6), 16);

    // 最大値と最小値の和を算出
    let buf = Math.max(r, g, b) + Math.min(r, g, b);

    // 補色のRGB値を計算し、16進数に変換
    let cr = ((buf - r).toString(16)).padStart(2, '0');
    let cg = ((buf - g).toString(16)).padStart(2, '0');
    let cb = ((buf - b).toString(16)).padStart(2, '0');

    // 結果を結合して返す
    return "rgba(#" +cr + cg + cb +", .5)";
  }

  // watchでlastAngleを監視、旧値と新値を比較して差分でキャンバス内のオブジェクトを回転させる
  watch(lastAngle, (newVal, oldVal) => {
    let newangle = newVal - oldVal;
    if (process.env.VUE_APP_LOGGING === 'true') console.log(newangle)

    // 背景画像を回転
    canvas.value.backgroundImage.rotate(lastAngle.value);
    // 背景画像の中心点を計算
    // var centerX = canvas.value.getWidth() / 2;
    // var centerY = canvas.value.getHeight() / 2;
    // キャンバス上の全てのオブジェクトに対してループ処理を行い、angle度回転させる
    // canvas.value.getObjects().forEach(function(object) {
    //   if (object.label === 'clipArea' || object.customId === 'mosaic') return;
    //     // オブジェクトを背景画像の中心を基準に回転
    //     object.rotate(object.angle + newangle);
    //     // 回転の基点を背景画像の中心に設定
    //     // var oldCenter = object.getCenterPoint();
    //     // var newLeft = centerX + (oldCenter.x - centerX) * Math.cos(Math.PI / 4) - (oldCenter.y - centerY) * Math.sin(Math.PI / 4);
    //     // var newTop = centerY + (oldCenter.x - centerX) * Math.sin(Math.PI / 4) + (oldCenter.y - centerY) * Math.cos(Math.PI / 4);
    //     // object.set({ top: newTop, left: newLeft }).setCoords();
    // });

    // キャンバスを再描画
    canvas.value.renderAll();

    setTimeout(() => {
      canvas.value.getObjects().forEach(function(object) {
        if (object.customId === 'mosaic') {
          applyMosaic(object);
        }
      });
    }, 500);
  });

  async function relayUploadImage(base64Image, serializedJsonLog, serializedJsonString) {
    if (base64Image) {
      isLoading.value = true;
      isImageSetModalOpen.value = false;
      const file = await imageCompression.getFilefromDataUrl(base64Image);
      handleImageCompression(file, serializedJsonLog ? true : false);
    }

    // serializedJsonLogを読み込む
    if (serializedJsonLog) {
      canvas.value.loadFromJSON(serializedJsonLog, () => {
        canvas.value.renderAll();
        canvas.value.getObjects().forEach((obj) => {
          if (obj.customId == 'mosaic') {
            createMosaic(obj);
            canvas.value.remove(obj);
          }
        });
      });
    }

    if (serializedJsonString) {
      // serializedJsonStringの内容はJSON形式の文字列なのでJSON.parseでオブジェクトに変換
      let decodeSerializedJson = JSON.parse(serializedJsonString)
      // decodeSerializedJson = JSON.parse(decodeSerializedJson);

      blackboardHeader.value = {
        user_name: decodeSerializedJson?.user_name ? decodeURIComponentSafe(decodeSerializedJson.user_name) : '',
        site_name: decodeSerializedJson?.site_name ? decodeURIComponentSafe(decodeSerializedJson.site_name) : '',
        site_sub_name: decodeSerializedJson?.site_sub_name ? decodeURIComponentSafe(decodeSerializedJson.site_sub_name) : '',
        unloading_date: decodeSerializedJson?.unloading_date ? decodeURIComponentSafe(decodeSerializedJson.unloading_date) : '',
        return_slipCD: decodeSerializedJson?.return_slipCD ? decodeURIComponentSafe(decodeSerializedJson.return_slipCD) : '',
      }
      blackboardBody.value = decodeSerializedJson.rentRepairDetail.length == 1 ? decodeURIComponentSafe(decodeSerializedJson.rentRepairDetail[0].repair_detail) : '';
      blackboardRentRepairDetails.value = decodeSerializedJson.rentRepairDetail.length >= 1 ? decodeSerializedJson.rentRepairDetail : [];

      blackboardRentRepairDetails.value.forEach(item => {
        item.kizai_name = decodeURIComponentSafe(item.kizai_name);
        item.repair_detail = decodeURIComponentSafe(item.repair_detail);
      });
    }
  }

  function uploadImage(base64Image, serializedJsonLog, serializedJsonString) {
    relayUploadImage(base64Image, serializedJsonLog, serializedJsonString);
    return serializedJsonString;
  }

  // 文字列のデコード
  function decodeURIComponentSafe(encodedURI) {
    try {
      return decodeURIComponent(encodedURI);
    } catch (e) {
      return encodedURI;
    }
  }

  function downloadImage() {
    if (setting.value.base64Url) {
      let currentViewportTransform = canvas.value.viewportTransform;
      const base64 = createDownloadImage();
      canvas.value.viewportTransform = currentViewportTransform;
      return base64;
    } else {
      return '';
    }
  }
  function downloadLog() {
    if (setting.value.base64Url) {
      return JSON.stringify(canvas.value);
    } else {
      return '';
    }
  }

  onMounted(() => {
    window.d = window.d || {}

    window.d.uploadImage = uploadImage;
    window.d.downloadImage = downloadImage;
    window.d.downloadLog = downloadLog;
  });
</script>
